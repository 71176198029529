import { initializeApp } from "firebase/app";
import { getMessaging} from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyDa-OCf1vHX8a9Ir9X0q7yKz9-ns6ddOKY",
    authDomain: "g-v-law-app-cbfc7.firebaseapp.com",
    projectId: "g-v-law-app-cbfc7",
    storageBucket: "g-v-law-app-cbfc7.appspot.com",
    messagingSenderId: "990818289381",
    appId: "1:990818289381:web:522647466f95cda33b360c",
    measurementId: "G-RXM6E0P01Y"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);