/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import style from "../Style/Task.module.css";
import {useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { ADD_TODO_URL, TEAM_VIEW_URL, VIEW_ADVOCATE_CLIENT_URL, VIEW_CASE_URL } from "../config/config";
import moment from "moment";

const YourComponent = ({ colClass }) => {
  var curr = new Date();
  var date = curr.toISOString().substring(0,10);

  const [expiryDate, setExpiryDate] = useState("");
  const [JudgementDate, setJudgementDate] = useState("");
  const [startDate, setStartDate] = useState(date);
  const [selectedAdvocate, setSelectedAdvocate] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [hearingDate, setHearingDate] = useState("");
  const [daysDifference, setDaysDifference] = useState(0);
  const [email, setEmail] = useState("");
  const [caseNo, setCaseNo] = useState('');
  const [title, setTitle] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [number, setDifferenceByDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [fname, setFname] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [advocateName, setAdvocateName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [Advocate, setAdvocate] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [newUserData, setUserData] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  const navigate = useNavigate();

  const Data = {
    advocateName: selectedAdvocate?.label,
    clientName: selectedClient.label,
    caseNo: caseNo,
    caseType:
      clients.filter((elem) => elem._id === selectedClient)[0]?.caseType || "",
    courtType:
      clients.filter((elem) => elem._id === selectedClient)[0]?.courtType || "",
    clientId: selectedClient.value,
    advocateId: selectedAdvocate?.value,
    email: email,
    daysDifference: daysDifference,
    hearingDate: JudgementDate,
    startDate: startDate,
    endDate: JudgementDate,
    expiryDate: expiryDate,
    daysToExpire: number,
    title: title,
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData") || newUserData;
    if (userData) {
      setUserData(userData);
    } else {
      alert("Session Expired.");
      navigate("/");
    }
    axios
      .get(`${TEAM_VIEW_URL}`)
      .then((response) => {
        console.log(response);
        const TeamOptions = [];
        if (Array.isArray(response.data.Data)) {
          response.data.Data.forEach((elem) => {
            let obj = {};
            obj["label"] = elem?.fullname;
            obj["value"] = elem?._id;
            TeamOptions.push(obj);
          });
          setTeamOptions(TeamOptions);
          console.log(TeamOptions);
          setAdvocate(response.data.Data);
        } else {
          console.error("Advocate data is not an array:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });

    fetch(`${VIEW_CASE_URL}`, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then(async (res) => {
        const clientList = await res.json();
        console.log(clientList);
        if (res.ok) {
          if (Array.isArray(clientList.data)) {
            const clientOption = [];
            clientList.data.forEach((elem) => {
              let obj = {};
              if(elem.clientInfo){
                obj["label"] = elem.clientInfo[0]?.clientFullName;
                  obj["value"] = elem.clientInfo[0]?._id;
                  clientOption.push(obj);
                // elem.clientInfo.forEach((ele) => {
                //   obj["label"] = ele?.clientFullName;
                //   obj["value"] = elem?._id;
                //   clientOption.push(obj);
                // });
              }else{
                obj["label"] = elem?.name;
                obj["value"] = elem?._id;
                clientOption.push(obj);
              }
            });
            setClientOptions(clientOption);
            setClients(clientList.data);
            setClientsList(clientList.data)
          } else {
            console.error("Clients data is not an array:", clientList.data);
          }
        } else {
          console.error("Error fetching data");
        }
      })
      .catch((err) => {
        console.log("Record not found");
      });
  },[navigate, newUserData]);

  const getFilteredClientData = (e) => {
    console.log(clients,'e : ', e);
    const client = clientsList.filter(data => data.advocateId === e.value);
    const clientOption = [];
    client.forEach((elem) => {
      let obj = {};
      if(elem.clientInfo){
        obj["label"] = elem?.clientInfo[0]?.clientFullName;
          obj["value"] = elem?.clientId;
          obj["clientemail"] = elem?.clientInfo[0]?.clientemail;
          clientOption.push(obj);
        // elem.clientInfo.forEach((ele) => {
        //   obj["label"] = ele?.clientFullName;
        //   obj["value"] = elem?.clientId;
        //   obj["clientemail"] = ele?.clientemail;
        //   clientOption.push(obj);
        // });
      }else{
        obj["label"] = elem?.name;
        obj["value"] = elem?.clientId;
        obj["clientemail"] = elem?.clientemail;
        clientOption.push(obj);
      }
    });
    setSelectedAdvocate(e);
    setClientOptions(clientOption)
    // setClients(client);
    // const {name, value} = e.target;
    // console.log('e : ', e.target, " ", "name : ", name, "value : ", value);
    // console.log("clients : ", client , 'newClientData : ', newClientData, JSON.stringify(newClientData));
  };

  const updateClientData = (e) => {
    console.log(clients,'e : ', e);
    const client = clientsList.filter(data => data.clientId === e.value);
    client[0].clientemail = e.clientemail;
    console.log("client : ", client);
    if(client[0].caseHistory.length > 0){
      var newCurr = new Date(client[0].caseHistory[0].hearingDate);
      var newDate = newCurr.toISOString().substring(0,10);
      console.log("newDate : ", newDate, "newCurr : ", newCurr);
      setStartDate(newDate);
      setJudgementDate(date);
    }else{
      setStartDate(date);
    }
    setClients(client);
    setCaseNo(client[0]?.caseNo);
    setEmail(client[0]?.clientemail)
    // const {name, value} = e.target;
    // console.log('e : ', e.target, " ", "name : ", name, "value : ", value);
    // console.log("clients : ", client , 'newClientData : ', newClientData, JSON.stringify(newClientData));
  };

  // eslint-disable-next-line no-unused-vars
  const findClients = (advocateId) => {
    if (!advocateId) {
      setClients([]);
    }
    fetch(
      `${VIEW_ADVOCATE_CLIENT_URL}${advocateId}`,
      {
        method: "GET",
        headers: { "content-type": "application/json" },
      }
    )
      .then(async (res) => {
        const clientList = await res.json();
        console.log(clientList);
        if (res.ok) {
          if (Array.isArray(clientList.data)) {
            setClients(clientList.data);
          } else {
            console.error("Clients data is not an array:", clientList.data);
          }
        } else {
          console.error("Error fetching data");
        }
      })
      .catch((err) => {
        console.log("Record not found");
      });
  };

  const handleJudgementDateChange = (e) => {
    const newJudgementDate = e.target.value;
    if(newJudgementDate){
      setJudgementDate(newJudgementDate);
      const difference = calculateDateDifference(newJudgementDate, expiryDate);
      setDaysDifference(difference);
    }else{
      setJudgementDate("");
    }
    console.log(newJudgementDate, "==============");
  };

  // eslint-disable-next-line no-unused-vars
  const handleExpiryDateChange = (e) => {
    const newExpiryDate = e.target.value;
    setExpiryDate(newExpiryDate);
    const difference = calculateDateDifference(JudgementDate, newExpiryDate);
    setDaysDifference(difference);
  };

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDifference = end - start;
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  };

  const handleDateChange = (e) => {
    if(e.target.value){
      const selectedDate = new Date(e.target.value);
      const minExpiryDate = new Date(selectedDate);
      minExpiryDate.setDate(selectedDate.getDate());
      setExpiryDate(minExpiryDate.toISOString().split("T")[0]);
    }
  };

  const handleSubmit = async (e) => {
    console.log(Data, "---------------------");
    // console.log(Data);
    e.preventDefault();
    // const apiEndpoint =
    //   "https://jsonplaceholder.typicode.com/posts/1/comments./?endpoint";
    // const formData = {
    //   fname,
    //   advocateName,
    //   email,
    //   daysDifference,
    //   JudgementDate,
    //   expiryDate,
    // };

    fetch(`${ADD_TODO_URL}`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(Data),
    })
      .then((res) => res.json())
      .then((data) => {
        alert("Successfully Added.");
        navigate('/Alltodo')
        console.log("Case record uploaded:", data);
      })
      .catch((err) => {
        alert("Something went wrong, Please try again later!");
        console.error("Error uploading case record:", err);
      });
  };

  

  return (
    <>
      <div className={colClass}>
        <form method="post">
          <h3 className={style.heanding}>Reminder Date</h3>
          <div
            className={`${style.block} ${style.main} row col-12 ms-0 me-0 ps-3 pe-0`}
          >
            {/* <div className="col-xl-6 col-12">
            <select
              className={`col-xl-4 col-12 ${style.lastName}`}
              value={selectedAdvocate}
              name="selectedAdvocate"
              onChange={(e) => {
                const selectedName = e.target.value;
                setSelectedAdvocate(selectedName);
                findClients(selectedName)
              }}
            >
              <option value="">Select an advocate</option>
              {Advocate && Advocate.map((advocate) => (
                <option key={advocate._id} value={advocate._id}>
                  {advocate.fullname} ({advocate.designation})
                </option>
              ))}
            </select>
          </div> */}
            <div className="col-6 text-start">
              <label className="p-2">Select Advocate</label>
              <Select
                className={`col-12 ${style.Advocate}`}
                name="selectedAdvocate"
                options={teamOptions}
                onChange={(e) => {
                  getFilteredClientData(e);
                }}
              ></Select>
            </div>
            {/* <div className="col-xl-6 col-12 ">
            <select
              className={`col-xl-4 col-12 ${style.lastName}`}
              value={selectedClient}
              name="fname"
              onChange={(e) => {
                const selectedName = e.target.value;
                setSelectedClient(selectedName);
              }}
            >
              <option value="">Select an Client</option>
              {clients && clients.map((client) => (
                <option key={client._id} value={client._id}>
                  {client.name} ( {client.caseNo} )                </option>
              ))}
            </select>
          </div> */}
            <div className="col-6 text-start">
              <label className="p-2">Select Client</label>
              <Select
                className={`col-12 ${style.Advocate}`}
                name="selectedAdvocate"
                options={clientOptions}
                onChange={(e) => {
                  setSelectedClient(e);
                  updateClientData(e)
                }}
              ></Select>
            </div>
            {/* <div className="col-xl-6 col-12">
            <input
              type="text"
              name="fname"
              placeholder="Advocate Name"
              className={style.lastName}
              onChange={(e) => {
                setAdvocateName(e.target.value);
              }}
              required
            />
          </div> */}
          </div>
          <div className={`${style.main} col-12 row ms-0 me-0 ps-3 pe-0`}>
            <div className="col-12 d-flex">
              <div className="col-6 text-start">
                <label className="ps-2" htmlFor="due">Please select Previous Date</label>
                <div className={`col-12 ${style.mainTime}`}>
                  <div className={style.term}>
                    <input
                      className="ms-0"
                      type="date"
                      name="JudgementDate"
                      value={startDate}
                      onChange={(e) => {
                        console.log("e.target.value : " + e.target.value);
                        setStartDate(e.target.value);
                        handleDateChange(e);
                      }}
                      required
                    />
                  </div>
                  {/* <div className={`${style.term} ${style.termexpiry}`}>
              <input
                type="date"
                name="expiryDate"
                value={expiryDate}
                min={expiryDate}
                onChange={(e) => {
                  handleExpiryDateChange(e);
                  handleDateChange(e);
                }}
                required
              />
            </div> */}
                </div>
              </div>
              <div className="col-6 text-start">
                <label className="ps-2" htmlFor="due">Please select Next Date</label>
                <div className={`col-12 ${style.mainTime}`}>
                  <div className={style.term}>
                    <input
                      className="ms-0"
                      type="date"
                      name="JudgementDate"
                      value={JudgementDate}
                      onChange={(e) => {
                        handleJudgementDateChange(e);
                        handleDateChange(e);
                      }}
                      required
                    />
                  </div>
                  {/* <div className={`${style.term} ${style.termexpiry}`}>
              <input
                type="date"
                name="expiryDate"
                value={expiryDate}
                min={expiryDate}
                onChange={(e) => {
                  handleExpiryDateChange(e);
                  handleDateChange(e);
                }}
                required
              />
            </div> */}
                </div>
              </div>
            </div>
            {/* <div className={style.main_2}></div> */}
            <div className="col-12 d-flex">
              <div className="col-6 text-start">
                <label className="p-2" htmlFor="reminders">Case No.</label>
                <div className={`col-12 ${style.difference}`}>
                  <div className={style.mainreminders}>
                    <input
                      className="ms-0"
                      name="caseNo"
                      placeholder="caseNo"
                      value={caseNo}
                      onChange={(e) => {
                        setCaseNo(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 text-start">
                <label className="p-2" htmlFor="reminders">Email</label>
                <div className={`col-12 ${style.difference}`}>
                  {/* <input
                type="number"
                name="number"
                placeholder="Days Difference"
                onChange={(e) => {
                  setDifferenceByDate(e.target.value);
                }}
                required
              /> */}
                  <div className={style.mainreminders}>
                    <input
                      className="ms-0"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={clients[0]?.clientemail}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex">
              <div className="col-6 text-start">
                <label className="p-2" htmlFor="reminders">Title</label>
                <div className={`col-12 ${style.difference}`}>
                  <div className={style.mainreminders}>
                    <input
                      className="ms-0"
                      name="title"
                      placeholder="Title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 justify-content-center text-center">
            {/* <Link
              to="#"
              type="submit"
              onClick={handleSubmit}
              className={`${style.Button} ${style.btn}`}
            >
              Submit
            </Link> */}
            <button type="submit" onClick={handleSubmit} className={`${style.Button}`}>Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default YourComponent;
