import React, { useState, useEffect } from "react";
import style from "../Style/PdfClient.module.css";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import img from "../Assent/logo.png";
import dowloadimg from "../Assent/download.png";
import { BILL_VIEW_URL, FIND_BILL_VIEW_URL } from "../config/config";
import { Tooltip } from "@material-ui/core";

const BillPdfs = ({colClass}) => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [Advocate, setAdvocate] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [matchingAdvocate, setMatchingAdvocate] = useState("");
  const [selectedAdvocateId, setSelectedAdvocateId] = useState("");

  useEffect(() => {
    axios
      .get(BILL_VIEW_URL)
      .then((response) => {
        if (Array.isArray(response.data.Data)) {
          setAdvocate(response.data.Data);
        } else {
          console.error("Advocate data is not an array:");
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${FIND_BILL_VIEW_URL}${id}`)
      .then((res) => {
        setData(res.data.data);
        if (res.data.data) {
          let Advocateid = res.data.data.Advocate;
          setSelectedAdvocateId(Advocateid);
        } else {
          console.log("Data Not Find");
          return false;
        }
      })
      .catch((err) => console.error(err));
  }, [id]);

  const AdvocateId = selectedAdvocateId;
  const Advocates = Advocate;

  useEffect(() => {
    if (AdvocateId && Advocates) {
      const Advocatename = Advocates.find(
        (advocate) => advocate._id === AdvocateId
      );
      console.log(Advocatename);

      setMatchingAdvocate(Advocatename?.fullname);
    }
  }, [AdvocateId, Advocates]);

  //PDF Dowloads Code
  const downloadFile = () => {
    const input = document.getElementById("data");
    if (!input) return;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("client_data.pdf");
    });
  };

  return (
    <>
      <div className="col-12 d-flex">
        <div id="data" className={`${style.pdf} ${colClass === 'col-12' ? 'col-9' : 'col-9'} p-1`}>
          <h2 className={style.pdfh2}>Invoice</h2>

          <img src={img} className={style.imgiconBillPdf} alt="100" />

          <div className="col-12">
            <div className="d-flex col-3">
              <div className="text-end col-6">
                <label>
                  <b>Bill From:-</b>
                </label>
              </div>
              <div className="ms-2 col-6 text-start">
                <label>{data ? data.clientName : "Loading..."}</label>
              </div>
            </div>

            <div className="d-flex col-3">
              <div className="text-end col-6">
                <label>
                  <b>City:-</b>
                </label>
              </div>
              <div className="ms-2 col-6 text-start">
                <label>{data ? data.address : "Loading..."}</label>
              </div>
            </div>
          </div>

          <div className="col-12 p-3">
            <div className={style.pdfContentTableCenter}>
              <table
                style={{
                  borderCollapse: "collapse",
                  border: "0px solid black",
                }}
                className="justify-content-center table mb-0 table-striped table-sm table-bordered"
              >
                <thead>
                  <tr>
                    <th className="col-4">perticulars</th>
                    <th className="text-end col-2">Amount</th>
                    <th className="col-2">Rate</th>
                    <th className="col-1">Tax(%)</th>
                    <th className="text-end col-3">Total Amount(INR)</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.billDetails &&
                    data.billDetails.map((item, index) => (
                      <tr key={index}>
                        <td className="col-4">{item.perticular}</td>
                        <td className="text-end col-2">{item.amount}/-</td>
                        <td className="col-2">{item.rate}</td>
                        <td className="col-1">{item.tax}</td>
                        <td className="text-end col-3">{item.totalAmt}/-</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="col-12 d-flex">
                <div className="col-6"></div>
                <div className="col-6 m-0 p-0">
                  <div className="col-12 d-flex">
                    <label
                      className={`${style.borderLeftOfLabel} text-start col-6 ps-1`}
                      colSpan={2}
                    >
                      Sub Total
                    </label>
                    <label
                      className={`${style.borderLeftOfLabel} text-end col-6 pe-1`}
                    >
                      {data ? data.subTotalAmt : "Loading..."}
                    </label>
                  </div>
                  <div className="col-12 d-flex">
                    <label
                      className={`${style.borderLeftOfLabel} text-start col-6 ps-1`}
                    >
                      GST
                    </label>
                    <label
                      className={`${style.borderLeftOfLabel} text-end col-6 pe-1`}
                    >
                      {data ? data.gstAmount : "Loading..."}
                    </label>
                  </div>
                  <div className="col-12 d-flex">
                    <th
                      className={`${style.borderLeftOfLabel} text-start col-6 ps-1`}
                    >
                      <b>Total</b>
                    </th>
                    <th
                      className={`${style.borderLeftOfLabel} ${style.borderRoundedLastTh} text-end col-6 pe-1`}
                    >
                      {data ? data.totalAmount : "Loading..."}
                    </th>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label>Bill Attachments</label>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "0px solid black",
                  }}
                  className="justify-content-center table mb-0 table-striped table-sm table-bordered"
                >
                  <thead>
                    <tr>
                      <th className="col-2">#</th>
                      <th className="col-4">Attachment Name</th>
                      <th className="col-3">Uploaded On</th>
                      <th className="col-2">Action</th>
                    </tr>
                  </thead>
                  {data.attachmnets && (
                    <tbody className="col-12">
                      {data &&
                        data.billDetails &&
                        data.billDetails.map((item, index) => (
                          <tr key={index}>
                            <td>{item.perticular}</td>
                            <td>{item.amount}/-</td>
                            <td>{item.rate}</td>
                            <td>{item.tax}</td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
                {!data.attachmnets && (
                  <div className="ps-2">No Attachments Available</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${style.dowloadimgwidth} ${
            colClass === "col-12" ? "col-3" : "col-3"
          } d-flex justify-content-center align-items-center`}
        >
          <Tooltip placement="top" title="Download File" >
            <img
              src={dowloadimg}
              className={style.dowloadimg}
              onClick={downloadFile}
              alt="100"
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default BillPdfs;
