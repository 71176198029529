import React, { useState } from 'react'
// eslint-disable-next-line no-unused-vars
import MainBills from '../Component/MainBills'
import Darshboardsaid from '../Component/Darshboardside'
import NavBar from '../Component/NavBar'
import EventCalendars from '../Component/EventCalendars';
import mainDashboardStyle from "../Style/MainDashboard.module.css";

const EventCalendar = () => {
  const [saidbarColClass, setSaidbarColClass] = useState("col-2");
  const [mainDashboardColClass, setMainDashboardColClass] = useState("col-12");
  const [mainDashboardClass, setMainDashboardClass] = useState("col-10");
  const [activeComponent, setActiveComponent] = useState("EventCalendar");

  const toggleColClass = () => {
    setSaidbarColClass(saidbarColClass === "col-2" ? "col-1" : "col-2");
    setMainDashboardColClass(
      mainDashboardColClass === "col-10" ? "col-12" : "col-10"
    );
    setMainDashboardClass(mainDashboardClass === "col-10" ? "col-12" : "col-10");
  };
  const toggleActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <>
      <div className='col-12'>
        <div className='col-12'>
          <NavBar />
        </div>
        <div className="d-flex col-12">
          <div className={saidbarColClass}>
           <Darshboardsaid colClass={saidbarColClass} toggleColClass={toggleColClass} toggleActiveComponent={toggleActiveComponent} />
          </div>
          <div className={`${mainDashboardClass} ${mainDashboardStyle.height900}`}>
            {activeComponent === 'EventCalendar' && <EventCalendars colClass={mainDashboardColClass} />}
          </div>
        </div>
      </div>

    </>
  )
};


export default EventCalendar