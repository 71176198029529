import React, { useEffect, useState } from "react";
import style from "../Style/AddNewClient.module.css";
import styleTeamModel from "../Style/MainTeamMember.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { ADD_CLIENT_URL, CLIENT_VIEW_BY_ID_URL, DELETE_CLIENT_URL, TEAM_VIEW_URL, UI_CONFIG_URL, UPDATE_CLIENT_URL } from "../config/config";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";

const AddNewClints = ({ colClass }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedAdvocate, setSelectedAdvocate] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [teamOptionsList, setTeamOptionsList] = useState([]);
  const [caseTypesOptions, setCaseTypesOptions] = useState([]);
  const [courtTypesOptions, setCourtTypesOptions] = useState([]);
  const [courtCampusOptions, setCourtCampusOptions] = useState([]);

  const [clientType, setClientType] = useState("");
  const [clientClassification, setClientClassification] = useState("");
  const [caseTypes, setCaseTypes] = useState("");

  const [hideField, setHideField] = useState(false);

  const [date, setDate] = useState("");
  const [agreementNo, setAgreementNo] = useState("");
  const [payingAmount, setPayingAmount] = useState("");
  const [clientFullName, setClientFullName] = useState("");
  const [clientbusinessName, setClientBusinessName] = useState("");
  const [caseNo, setCaseNo] = useState("");
  const [year, setYear] = useState("");
  let [fullCaseNo, setFullCaseNo] = useState("");
  const [cnrNo, setCnrNo] = useState("");
  const [courtName, setCourtName] = useState("");
  const [courtCampus, setCourtCampus] = useState("");

  const [loanAgrement, setLoanAgrement] = useState("");
  const [clientaddress, setClientAddress] = useState("");
  const [clientstreetAddress, setClientStreetAddress] = useState("");
  const [clientstate, setClientState] = useState("");
  const [clientemail, setClientEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [oppositePartyFullName, setOppositePartyFullName] = useState("");
  const [oppositePartyBusinessName, setOppositePartyBusinessName] =
    useState("");
  const [oppositeAdvocateName, setOppositePartyAdvocateName] = useState("");
  const [oppositeMobileNumber, setOppositePartyMobileNumber] = useState("");
  const [oppositePartyAddress, setOppositePartyAddress] = useState("");
  const [oppositePartySpecificDetails, setOppositePartySpecificDetails] =
    useState("");
  const [receiveEmail, setReceiveEmail] = useState("");
  const [participateInSurveys, setParticipateInSurveys] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [participateOption, setParticipateOption] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [erroreceiveEmail, setErrorReceiveEmail] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorparticipateoption, setErrorParticipateOption] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errordate, setErrorDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorclientName, setErrorClientName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorparticipateinSurveys, setErrorParticipateInSurveys] =
    useState("");
    // eslint-disable-next-line no-unused-vars
  const [errorclientbusinessName, setErrorClientBusinessName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorclientaddress, setErrorClientAddress] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorclientstreetAddress, setErrorClientStreetAddress] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorclientstate, setErrorClientState] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorclientemail, setErrorClientEmail] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorcaseno, setErrorCaseNo] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorloanagrement, setErrorLoanAgrement] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [errorMobileNumber, setErrorMobileNumber] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [erroroppositePartyFullName, setErrorOppositePartyFullName] =
    useState("");
    // eslint-disable-next-line no-unused-vars
  const [erroroppositePartyBusinessName, setErrorOppositePartyBusinessName] =
    useState("");
    // eslint-disable-next-line no-unused-vars
  const [erroroppositePartyAddress, setErrorOppositePartyAddress] =
    useState("");
    // eslint-disable-next-line no-unused-vars
  const [erroroppositePartySpecificDetails,setErrorOppositePartySpecificDetails,
  ] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [Advocate, setAdvocate] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [uiConfig, setUiConfig] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const [clientInfo, setClientInfo] = useState([
    {
      clientFullName : "",
      clientFullNameError : "",
      mobileNumber : "",
      mobileNumberError : "",
      clientemail : "",
      clientemailError : "",
      clientbusinessName : "",
      clientbusinessNameError : "",
    },
  ]);

  const [oppositeClientInfo, setOppositeClientInfo] = useState([{
    oppositePartyFullName : "",
    oppositePartyFullNameError : "",
    oppositeAdvocateName : "",
    oppositeAdvocateNameError : "",
    oppositeMobileNumber : "",
    oppositeMobileNumberError : "",
  }])

  const clientTypeOption = [
    {
      label: "Behalf",
      value: "Behalf",
    },
    {
      label: "Against",
      value: "Against",
    },
  ];

  const clientClassificationOptions = [
    {
      label: "Civil",
      value: "Civil",
    },
    {
      label: "Criminal",
      value: "Criminal",
    },
    {
      label: "Consumer",
      value: "Consumer",
    },
  ];

  const validateForm = () => {
    let errors = { };

    if (date.trim() === "") {
      errors.date = "*date is required";
    } else {
      setDate(date);
    }

    if (agreementNo.trim() === "") {
      errors.agreementNo = "*agreement number is required";
    } else {
      setAgreementNo(agreementNo);
    }

    if (payingAmount.trim() === "") {
      errors.payingAmount = "*paying amount is required";
    } else {
      setPayingAmount(payingAmount);
    }

    // if (clientFullName.trim() === "") {
    //   errors.clientFullName = "*FullName is required";
    // } else {
    //   setClientFullName(clientFullName);
    // }

    // if (clientbusinessName.trim() === "") {
    //   errors.clientbusinessName = "*Reference Name is required";
    // } else {
    //   setClientBusinessName(clientbusinessName);
    // }

    // if (clientaddress.trim() === "") {
    //   errors.clientaddress = "*address is required";
    // }else{
    //   setClientAddress(clientaddress);
    // }

    // if (clientstreetAddress.trim() === "") {
    //   errors.clientstreetAddress = "*streetAddress is required";
    // }else{
    //   setClientStreetAddress(clientstreetAddress);
    // }

    // if (clientstate.trim() === "") {
    //   errors.clientstate = "*state is required";
    // }else{
    //   setClientState(clientstate);
    // }

    // if (clientemail.trim() === "") {
    //   errors.clientemail = "*Email is required";
    // } else if(!isValidEmail(clientemail.trim())) {
    //   errors.clientemail = "*Email is not valid";
    // }else{
    //   setClientEmail(clientemail);
    // }

    // if (hideField && caseNo.trim() === "") {
    //   errors.caseNo = "*CaseNo is required";
    // }else{
    //   setCaseNo(caseNo);
    // }

    // if (mobileNumber.trim() === "") {
    //   errors.mobileNumber = "*Mobile Number is required";
    // } else if (!isValidMobileNumber(mobileNumber)) {
    //   errors.mobileNumber = "*Mobile Number must be 10 digits";
    // } else {
    //   setMobileNumber(mobileNumber);
    // }

    // if (hideField && loanAgrement.trim() === "") {
    //   errors.loanAgrement = "*LoanAgrement is required";
    // }else{
    //   setLoanAgrement(loanAgrement);
    // }

    // if (hideField && participateOption.trim() === "") {
    //   errors.participateOption = "*Case type is required";
    // }else{
    //   setParticipateOption(participateOption);
    // }

    if (receiveEmail?.trim() === "") {
      errors.receiveEmail = "*Sending a monthly email is required.";
    } else {
      setReceiveEmail(receiveEmail);
    }

    if (participateInSurveys?.trim() === "") {
      errors.participateInSurveys =
        "* Participate in our client surveys is required.";
    } else {
      setParticipateInSurveys(participateInSurveys);
    }

    // if (oppositePartyFullName?.trim() === "") {
    //   errors.oppositePartyFullName = "*FullName is required";
    // } else {
    //   setOppositePartyFullName(oppositePartyFullName);
    // }

    // if (oppositeMobileNumber?.trim() === "") {
    //   errors.oppositeMobileNumber = "*Mobile Number is required";
    // } else {
    //   setOppositePartyMobileNumber(oppositeMobileNumber);
    // }

    // if (oppositePartyBusinessName?.trim() === "") {
    //   errors.oppositePartyBusinessName = "*BusinessName is required";
    // }else{
    //   setOppositePartyBusinessName(oppositePartyBusinessName);
    // }

    // if (oppositePartyAddress.trim() === "") {
    //   errors.oppositePartyAddress = "*Address is required";
    // }else{
    //   setOppositePartyAddress(oppositePartyAddress);
    // }

    // if (oppositePartySpecificDetails.trim() === "") {
    //   errors.oppositePartySpecificDetails = "*Details is required";
    // }else{
    //   setOppositePartySpecificDetails(oppositePartySpecificDetails);
    // }

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(clientemail)) {
    //   errors.clientemail = "*Invalid email address";
    // }
    console.log("errors : ", JSON.stringify(errors));
    setErrorMessages(errors); 
    return Object.keys(errors).length === 0;
  };

  const checkClientInfoErrors = () => {
    let errorsClientInfo = false;
    clientInfo.forEach((elem , i) => {
      if(elem.clientbusinessName.trim() === ""){
        elem.clientbusinessNameError = "Please enter a client Reference name";
        errorsClientInfo = true;
      }else if(elem.clientBusinessName !== "" || elem.clientBusinessName !== undefined){
        elem.clientBusinessNameError = "";
        errorsClientInfo = false;
      }

      if(elem.clientFullName.trim() === ""){
        elem.clientFullNameError = "Please enter a Full name";
        errorsClientInfo = true;
      }else if(elem.clientFullName !== "" || elem.clientFullName !== undefined){
        elem.clientFullNameError = "";
        errorsClientInfo = false;
      }

      if(elem.clientemail.trim() === ""){
          elem.clientemailError = "Please enter a client email";
          errorsClientInfo = true;
      }else if(!isValidEmail(elem.clientemail.trim())){
        elem.clientemailError = "Please enter a valid email";
        errorsClientInfo = true;
      }else if(elem.clientemail !== "" || elem.clientemail !== undefined){
        elem.clientemailError = "";
        errorsClientInfo = false;
      }

      if(elem.mobileNumber.trim() === ""){
        elem.mobileNumberError = "Please enter a mobile number";
        errorsClientInfo = true;
      }else if(!isValidMobileNumber(elem.mobileNumber)){
        elem.mobileNumberError = "Please enter a 10 digit mobile number";
        errorsClientInfo = true;
      }else if(elem.mobileNumber !== "" || elem.mobileNumber !== undefined){
        elem.mobileNumberError = "";
        errorsClientInfo = false;
      }
    });
    return errorsClientInfo;
  }

  const checkOppositeClientInfoErrors = () => {
    let oppositeClientInfoError = false;
    oppositeClientInfo.forEach(elem => {
      if(elem.oppositePartyFullName.trim() === ""){
        elem.oppositePartyFullNameError = "Please enter a full name";
        oppositeClientInfoError = true;
      }else if(elem.oppositePartyFullName !== "" || elem.oppositePartyFullName !== undefined){
        elem.oppositePartyFullNameError = "";
        oppositeClientInfoError = false;
      }

      if(elem.oppositeAdvocateName.trim() === ""){
        elem.oppositeAdvocateNameError = "Please enter a advocate name";
        oppositeClientInfoError = true;
      }else if(elem.oppositeAdvocateName !== "" || elem.oppositeAdvocateName !== undefined){
        elem.oppositeAdvocateNameError = "";
        oppositeClientInfoError = false;
      }

      if(elem.oppositeMobileNumber.trim() === ""){
        elem.oppositeMobileNumberError = "Please enter a mobile number";
        oppositeClientInfoError = true;
      }else if(!isValidMobileNumber(elem.oppositeMobileNumber)){
        elem.oppositeMobileNumberError = "Please enter a 10 digit mobile number";
        oppositeClientInfoError = true;
      }else if(elem.oppositeMobileNumber !== "" || elem.oppositeMobileNumber !== undefined){
        elem.oppositeMobileNumberError = "";
        oppositeClientInfoError = false;
      }
    });
    return oppositeClientInfoError;
  }

// eslint-disable-next-line no-unused-vars
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

// eslint-disable-next-line no-unused-vars
  const isValidMobileNumber = (mobileNumber) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobileNumber);
  };

  const renderErrorMessage = (fieldName) =>
    errorMessages[fieldName] && (
      <div className={style.error}>{errorMessages[fieldName]}</div>
    );

// eslint-disable-next-line no-unused-vars
  const renderNewErrorMessage = (fieldName, fieldValue, index) =>
    errorMessages[fieldName] && (
      <div className={style.error}>{errorMessages[fieldName][index][fieldValue]}</div>
    );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (!user) {
      navigator("/");
    }
    // calling uiConfig
    getUiConfig();
    initOptions();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: TEAM_VIEW_URL,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (Array.isArray(response.data.Data)) {
          const TeamOptions = [];
          response.data.Data.forEach((elem) => {
            let obj = {};
            obj["label"] = elem?.fullname;
            obj["value"] = elem?._id;
            TeamOptions.push(obj);
          });
          console.log(TeamOptions);
          setTeamOptions(TeamOptions);
          setTeamOptionsList(TeamOptions);
          setAdvocate(response.data.Data);
        } else {
          console.error("Advocate data is not an array:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initOptions = () => {
    if (id !== "create") {
      fetch(`${CLIENT_VIEW_BY_ID_URL}${id}`, {
        method: "GET",
        headers: { "content-type": "application/json" },
      })
        .then(async (res) => {
          if (res.ok) {
            const record = await res.json();
            console.log(record.data);
            setDate(record?.data?.date);
            setAgreementNo(record?.data?.agreementNo);
            setPayingAmount(record?.data?.payingAmount);
            setClientType({
              label: record?.data?.clientType,
              value: record?.data?.clientType,
            });
            setClientFullName(record?.data?.name);
            setClientBusinessName(record?.data?.bName);
            setCaseTypes(record?.data?.caseType);
            setClientClassification({
              label : record?.data?.clientClassification, 
              value: record?.data?.clientClassification
            });
            setFullCaseNo(record?.data?.fullCaseNo);
            setYear(record?.data?.year);
            setCaseNo(record?.data?.caseNo);
            setCnrNo(record?.data?.cnrNo);
            setCourtName(record?.data?.courtName);
            setAdvocate(record?.data?.advocateName);
            setMobileNumber(record?.data?.mobileNo);
            setClientAddress(record?.data?.address1);
            setClientStreetAddress(record?.data?.address2);
            setClientState(record?.data?.state);
            setClientEmail(record?.data?.email);

            setOppositePartyFullName(record?.data?.oppPartyDetails?.oppname);
            setOppositePartyBusinessName(
              record?.data?.oppPartyDetails?.oppbName
            );
            setOppositePartyAdvocateName(
              record?.data?.oppPartyDetails?.oppAdvocateName
            );
            setOppositePartyMobileNumber(
              record?.data?.oppPartyDetails?.oppMobileNumber
            );
            setOppositePartyAddress(record?.data?.oppPartyDetails?.address);
            setOppositePartySpecificDetails(
              record?.data?.oppPartyDetails?.details
            );
            setReceiveEmail(
              record?.data?.addInfo?.oppPartyDetails?.receiveEmail
            );
            setParticipateInSurveys(
              record?.data?.addInfo?.oppPartyDetails?.surveys
            );
            setClientInfo(record?.data?.clientInfo);
            setOppositeClientInfo(record?.data?.oppPartyDetails?.oppositeClientInfo)
          } else {
            console.error("Error fetching data");
          }
        })
        .catch((err) => {
          console.log("Record not found");
        });
      setHideField(true);
    }
  };

  const getUiConfig = () => {
    axios
      .get(UI_CONFIG_URL)
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          const caseOptions = [];
          const courtTypeOptions = [];
          const courtCampusOptions = [];
          response?.data?.data[0]?.caseTypes.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            caseOptions.push(obj);
          });
          response?.data?.data[0]?.courtTypes.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            courtTypeOptions.push(obj);
          });
          response?.data?.data[0]?.courtCampus.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            courtCampusOptions.push(obj);
          });
          setCaseTypesOptions(caseOptions);
          setCourtTypesOptions(courtTypeOptions);
          setCourtCampusOptions(courtCampusOptions);
        } else {
          console.error("Advocate data is not an array:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let checkClientInfoError = checkClientInfoErrors();
    let checkOppositeClientInfoError = checkOppositeClientInfoErrors();
    let formIsValid = validateForm();

    console.log("formIsValid : " + formIsValid);

    if (formIsValid) {
      // Find the selected advocate object based on the selected name
      // const selectedAdvocateObject = Advocate.find(
      //   (advocate) => advocate._id == selectedAdvocate
      // );

      // if (!selectedAdvocateObject) {
      //   console.log("Advocate not found for Name:", selectedAdvocate);
      //   return;
      // }

      if (formIsValid && !checkClientInfoError && !checkOppositeClientInfoError) {
        const formData = {
          date: date,
          agreementNo: agreementNo,
          payingAmount: payingAmount,
          status: id === "create" ? "" : "RUNNING",
          clientClassification: clientClassification,
          clientFullName: clientFullName,
          clientbusinessName: clientbusinessName,
          clientType: clientType.value,
          caseNo: caseNo,
          cnrNo: cnrNo,
          year: year,
          caseType: caseTypes?.value,
          courtType: courtName?.value,
          courtCampusType: courtCampus.value,
          advocateName: selectedAdvocate.label,
          advocateId: selectedAdvocate.value,
          teamMembers: selectedTeamMember,
          loanAgrement: loanAgrement,
          clientaddress: clientaddress,
          clientstreetAddress: clientstreetAddress,
          clientstate: clientstate,
          clientemail: clientemail,
          mobileNumber: mobileNumber,
          oppositePartyFullName: oppositePartyFullName,
          oppositePartyBusinessName: oppositePartyBusinessName,
          oppositeAdvocateName: oppositeAdvocateName,
          oppositeMobileNumber: oppositeMobileNumber,
          oppositePartyAddress: oppositePartyAddress,
          oppositePartySpecificDetails: oppositePartySpecificDetails,
          // participateOption: participateOption,
          receiveEmail: receiveEmail,
          participateInSurveys: participateInSurveys,
          // Advocate: selectedAdvocateObject._id,
          clientInfo: clientInfo,
          oppositeClientInfo: oppositeClientInfo,
          fullCaseNo: fullCaseNo,
          isActive:false,
        };

        if (id === "create") {
          fetch(ADD_CLIENT_URL, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(formData),
          })
            .then((res) => {
              if(res.ok){
                alert('Client Successfully Added!')
                navigate("/ViewClient");
              }
            })
            .catch((err) => {
              console.error("Error uploading client record:", err);
            });
        } else {
          axios
            .put(`${UPDATE_CLIENT_URL}${id}`, formData)
            .then((response) => {
              // alert(response.data.Message);
              if(response.data?.StatusCode === 409){
                alert(response.data.Message);
                setDate(formData.date);
                setAgreementNo(formData.agreementNo);
                setPayingAmount(formData.payingAmount);
                setClientAddress(formData.clientAddress);
                setClientStreetAddress(formData.clientstreetAddress);
              }
              if(response.data?.StatusCode === 200){
                axios
                  .put(`${DELETE_CLIENT_URL}${id}`, {
                    isActive: false,
                  })
                  .then((res) => {
                    alert(response.data.Message);
                    navigate("/ViewClient");
                    // alert('Successfully Added.')
                  })
                  .catch((err) => {
                    alert('Something went wrong.')
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }

    setDate("");
    setAgreementNo("");
    setPayingAmount("");
    setClientFullName("");
    setClientBusinessName("");
    setCaseNo("");
    setLoanAgrement("");
    setClientAddress("");
    setMobileNumber("");
    setClientStreetAddress("");
    setClientState("");
    setClientEmail("");
    setOppositePartyFullName("");
    setOppositePartyBusinessName("");
    setOppositePartyAddress("");
    setOppositePartySpecificDetails("");
    setReceiveEmail("");
    setParticipateInSurveys("");
    setParticipateOption("");
    setSelectedAdvocate("");
  };

  const addMoreClientDetails = () => {
    setClientInfo([
      ...clientInfo,
      {
        clientFullName : "",
        clientFullNameError : "",
        mobileNumber : "",
        mobileNumberError : "",
        clientemail : "",
        clientemailError : "",
        clientbusinessName : "",
        clientbusinessNameError : "",
      },
    ]);
  }

  const removeMoreClientDetails = (i, e) => {
    clientInfo.splice(i, 1);
    setClientInfo([...clientInfo]);
  }

  const addMoreOppositeClientDetails = () => {
    setOppositeClientInfo([
      ...oppositeClientInfo,
      {
        oppositePartyFullName : "",
        oppositePartyFullNameError : "",
        oppositeAdvocateName : "",
        oppositeAdvocateNameError : "",
        oppositeMobileNumber : "",
        oppositeMobileNumberError : "",
      },
    ]);
  }

  const removeMoreOppositeClientDetails = (i, e) => {
    oppositeClientInfo.splice(i, 1);
    setOppositeClientInfo([...oppositeClientInfo]);
  }

  const onClientInfoChange = (e,i) => {
    const {name, value} = e.target;
    console.log('name: ' + name + ' value: ' + value);
    const onChangeClientInfo = [...clientInfo];
    onChangeClientInfo[i][`${name}`] = value;
    setClientInfo(onChangeClientInfo);
  }

  const onOppositeClientInfoChange = (e,i) => {
    const {name, value} = e.target;
    const onChangeOppositeClientInfo = [...oppositeClientInfo];
    onChangeOppositeClientInfo[i][`${name}`] = value;
    setOppositeClientInfo(onChangeOppositeClientInfo);
  }

  const years = Array.from(
    { length: 25 },
    (_, index) => new Date().getFullYear() - index
  );

  return (
    <div className={`${colClass} my-5 ${style.clint}`}>
      <h3>Add Client Registration Form & Deo-Volente</h3>
      {/* <h4>Date</h4> */}
      <form onSubmit={handleSubmit}>
        {/* <input
          type="date"
          className={style.date}
          name="date"
          placeholder="Selected Date"
          value={date}
          max={new Date().toISOString().substr(0, 10)}
          onChange={(e) => setDate(e.target.value)}
        />
        {renderErrorMessage("date")} */}

        <div className="d-flex row col-12">
                      <div className="col-xl-4 col-12">
                        <label>Date</label>
                        <input
                          type="date"
                          className={`${style.date} col-11`}
                          name="date"
                          placeholder="Selected Date"
                          value={date}
                          max={new Date().toISOString().substr(0, 10)}
                          onChange={(e) => setDate(e.target.value)}
                        />
                        {renderErrorMessage("date")}
                      </div>
                      <div className="col-xl-5 col-12">
                        <label>Agreement No.</label>
                        <input
                          type="text"
                          placeholder="Agreement No"
                          className={style.inputName}
                          value={agreementNo}
                          name = "agreementNo"
                          onChange={(e) => setAgreementNo(e.target.value)}
                        />
                        {renderErrorMessage("agreementNo")}
                      </div>
                    </div>

        <div className="d-flex col-12 row">          
          <div className="col-xl-4 col-12">
            <label>Client Type</label>
            <Select
              className={`col-xl-4 col-12 ${style.Advocate}`}
              name="selectedAdvocate"
              options={clientTypeOption}
              placeholder="Select Client Type"
              onChange={(e) => {
                setClientType(e);
              }}
            ></Select>
          </div>
          <div className="col-xl-4 col-12">
            <label>Client Classification</label>
            <Select
              className={`col-xl-4 col-12 ${style.Advocate}`}
              name="selectedClienClassification"
              placeholder="Select Client Classification"
              options={clientClassificationOptions}
              onChange={(e) => {
                setClientClassification(e);
              }}
            ></Select>
          </div>
        </div>
        <h5>client/ Account information</h5>
        <div className="row d-flex col-12">
        <div className="col-12">
            { clientInfo ?
              clientInfo.map((data, i) => (
                <div className="d-flex col-12">
                  <div key={i} className="col-10">
                    <div className="d-flex row col-12">
                      <div className="col-xl-5 col-12">
                        <label>Full Name</label>
                        <input
                          type="text"
                          placeholder="Full Name"
                          className={style.inputName}
                          value={data?.clientFullName}
                          name = "clientFullName"
                          onChange={(e) => onClientInfoChange(e,i)}
                        />
                        {data.clientFullNameError && <div className={style.error}>{data.clientFullNameError}</div>}
                        {/* {renderNewErrorMessage("errorsClientInfo", "clientFullName", i)} */}
                      </div>
                      <div className="col-xl-5 col-12">
                        <label>Reference Name</label>
                        <input
                          type="text"
                          placeholder="Reference Name"
                          className={style.inputName}
                          value={data?.clientbusinessName}
                          name = "clientbusinessName"
                          onChange={(e) => onClientInfoChange(e,i)}
                        />
                        {data.clientbusinessNameError && <div className={style.error}>{data.clientbusinessNameError}</div>}
                        {/* {renderNewErrorMessage("errorsClientInfo", "clientbusinessName", i)} */}
                      </div>
                    </div>
                    <div className="d-flex row col-12">
                      <div className="col-xl-5 col-12">
                        <label>Mobile Number</label>
                        <input
                          type="text"
                          maxLength={10}
                          className={style.inputName}
                          placeholder="Enter Mobile No."
                          value={data.mobileNumber}
                          name="mobileNumber"
                          onChange={(e) => onClientInfoChange(e,i)}
                        />
                        {data.mobileNumberError && <div className={style.error}>{data.mobileNumberError}</div>}
                        {/* {renderNewErrorMessage("errorsClientInfo", "mobileNumber", i)} */}
                      </div>
                      <div className="col-xl-5 col-12">
                          <label>Email Address</label>
                          <input
                            type="text"
                            placeholder="Enter your email address"
                            className={style.inputName}
                            value={data.clientemail}
                            name="clientemail"
                            onChange={(e) => onClientInfoChange(e,i)}
                          />
                          {data.clientemailError && <div className={style.error}>{data.clientemailError}</div>}
                          {/* {renderNewErrorMessage("errorsClientInfo", "clientemail", i)} */}
                        </div>
                    </div>
                    <hr className="col-10 fw-bold"></hr>
                  </div>
                  {i === 0 && 
                    <div className="col-2">
                        <div style={{cursor: "pointer"}}
                          className={`${styleTeamModel.innerTeam} ${styleTeamModel.plusIconBills} ${style.addjustedTopMargin} text-center col-12`}
                          onClick={addMoreClientDetails}
                        >
                          <IoIosAddCircleOutline />
                        </div>
                    </div>
                  }
                  {i > 0 && 
                    <div className="col-2">
                      <div style={{cursor: "pointer"}}
                        className={`${styleTeamModel.innerTeam} ${styleTeamModel.plusIconBills} ${style.addjustedTopMargin} text-center col-12`}
                        onClick={ (e) => removeMoreClientDetails(i, e)}
                      >
                        <IoIosRemoveCircleOutline />
                      </div>
                    </div>
                  }
                </div>
              )) : ""
            }
          </div>
        </div>
        <div className="row">
          {/* <div className="col-xl-4 col-12">
            <label>Case type :</label>
            <div className="col-xl-4 col-12">
              <input
                type="radio"
                className="me-2"
                name="participateOption"
                value="Bank"
                checked={participateOption === "Bank"}
                onChange={(e) => setParticipateOption(e.target.value)}
              />
              Bank
              <input
                type="radio"
                className="ms-3 me-2"
                name="participateOption"
                value="Other"
                checked={participateOption === "Other"}
                onChange={(e) => setParticipateOption(e.target.value)}
              />
              Other
            </div>
            <div className={style.error}> {errorparticipateoption} </div>
          </div> */}
          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Case Type</label>
              <Select
                className={`col-xl-4 col-12 ${style.Advocate}`}
                defaultValue={caseTypes}
                name="caseTypes"
                placeholder="Select Case Type"
                options={caseTypesOptions}
                // isMulti={true}
                onChange={(e) => {
                  // const selectedName = e.target.value;
                  setCaseTypes(e);
                }}
              ></Select>
            </div>
          ) : (
            ""
          )}
          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Case No.</label>
              <input
                type="text"
                value={caseNo}
                placeholder="Enter Case No"
                name="caseNo"
                className={style.inputName}
                onChange={(e) => setCaseNo(e.target.value)}
              />
              {renderErrorMessage("caseNo")}
            </div>
          ) : (
            ""
          )}
          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Year</label>
              {/* <input
                type="text"
                value={year}
                name="year"
                className={style.inputName}
                onChange={(e) => setYear(e.target.value)}
              /> */}
              <select className={`col-xl-4 col-12 ${style.Advocate}`} value={year} onChange={(e) => setYear(e.target.value)}>
              <option value="">Select a year</option>
              {years.map((eleYear) => (
                <option key={eleYear} value={eleYear}>
                  {eleYear}
                </option>
              ))}
            </select>
              {renderErrorMessage("year")}
            </div>
          ) : (
            ""
          )}
          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Full Case No</label>
              <input
                type="text"
                value={fullCaseNo = (caseTypes ? `${caseTypes.label}`.split(" -")[0]+'/' : '')+((caseNo) ? `${caseNo}/` : '')+(year || "")}
                name="fullCaseNo"
                placeholder="Enter Full Case Number"
                className={style.inputName}
                disabled = {fullCaseNo !== '' || fullCaseNo != null || fullCaseNo !== undefined}
                onChange={(e) => setFullCaseNo(e.target.value)}
              />
              {renderErrorMessage("fullCaseNo")}
            </div>
          ) : (
            ""
          )}
          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>CNR No.</label>
              <input
                type="text"
                value={cnrNo}
                placeholder="Enter CNR No"
                name="cnrNo"
                className={style.inputName}
                onChange={(e) => setCnrNo(e.target.value)}
              />
              {renderErrorMessage("caseNo")}
            </div>
          ) : (
            ""
          )}
          {/* {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Loan Agrement (Optional)</label>
              <input
                type="text"
                value={loanAgrement}
                name="loanAgrement"
                className={style.inputName}
                onChange={(e) => setLoanAgrement(e.target.value)}
              />
              {renderErrorMessage("loanAgrement")}
            </div>
          ) : (
            ""
          )} */}

          {/* <div className="col-xl-4 col-12">
            <label>Court</label>
            <select
              className={`col-xl-4 col-12 ${style.Advocate}`}
              value={courtName}
              name="selectedAdvocate"
              onChange={(e) => {
                const selectedName = e.target.value;
                setCourtName(selectedName);
              }}
            >
              <option value="">Select an advocate</option>
              <option  value="SUPREME COURT">
                  SUPREME COURT
                </option>
                <option  value="HIGH COURT">
                  HIGH COURT
                </option>
                <option  value="DISTRICT COURT">
                  DISTRICT COURT
                </option>
            </select>
          </div> */}
          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Court Name</label>
              <Select
                className={`col-xl-4 col-12 ${style.Advocate}`}
                name="selectedAdvocate"
                options={courtTypesOptions}
                placeholder="Select Court Name"
                onChange={(e) => {
                  setCourtName(e);
                }}
              ></Select>
            </div>
          ) : (
            ""
          )}

          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Court Campus Name</label>
              <Select
                className={`col-xl-4 col-12 ${style.Advocate}`}
                name="selectedAdvocate"
                options={courtCampusOptions}
                placeholder="Select Court Campus Name"
                onChange={(e) => {
                  setCourtCampus(e);
                }}
              ></Select>
            </div>
          ) : (
            ""
          )}

          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Advocate name</label>
              <Select
                className={`col-xl-4 col-12 ${style.Advocate}`}
                // value={selectedAdvocate}
                options={teamOptions}
                placeholder="Select Advocate Name"
                // isMulti={true}
                name="selectedAdvocate"
                onChange={(e) => {
                  // const selectedName = e.target.value;
                  setTeamOptions(teamOptionsList.filter( elem => elem.label !== e.label));
                  setSelectedAdvocate(e);
                }}
              >
                {/* <option value="">Select an advocate</option>
              {Advocate && Advocate.map((advocate) => (
                <option key={advocate._id} value={advocate._id}>
                  {advocate.fullname}
                </option>
              ))} */}
              </Select>
            </div>
          ) : (
            ""
          )}

          {hideField ? (
            <div className="col-xl-4 col-12">
              <label>Advocate Team Members</label>
              <Select
                className={`col-xl-4 col-12 ${style.Advocate}`}
                // value={selectedAdvocate}
                name="selectedAdvocate"
                options={teamOptions}
                placeholder="Advocate Team Members"
                isMulti={true}
                onChange={(e) => {
                  // const selectedName = e.target.value;
                  setSelectedTeamMember(e);
                }}
              >
                {/* <option value="">Select an advocate</option>
            {Advocate && Advocate.map((advocate) => (
              <option key={advocate._id} value={advocate._id}>
                {advocate.fullname}
              </option>
            ))} */}
              </Select>
            </div>
          ) : (
            ""
          )}
        </div>
        <label>Address</label>
        <textarea
          className={style.businessName}
          value={clientaddress}
          name="clientaddress"
          placeholder="Enter Address"
          onChange={(e) => setClientAddress(e.target.value)}
          rows="6"
          cols="200"
        ></textarea>
        {renderErrorMessage("clientaddress")}
        <div className="row">
          {/* <div className="col-xl-4 col-12">
            <label>Street address</label>
            <input
              type="text"
              className={style.inputName}
              placeholder="City..."
              value={clientstreetAddress}
              name="clientstreetAddress"
              onChange={(e) => setClientStreetAddress(e.target.value)}
            />
            {renderErrorMessage('clientstreetAddress')}
          </div>
          <div className="col-xl-4 col-12">
            <label>state/provimi</label>
            <input
              type="text"
              className={style.inputName}
              placeholder="state/provimi..."
              value={clientstate}
              name="clientstate"
              onChange={(e) => setClientState(e.target.value)}
            />
            {renderErrorMessage('clientstate')}
          </div> */}
          

          <h5>opposite party Details</h5>
          <div className="row d-flex col-12">
          <div className="col-12">
              { oppositeClientInfo ? 
                oppositeClientInfo.map((data,i) => (
                   <div className="col-12 d-flex">
                      <div className="col-11 d-flex row pe-2">
                        <div className="col-xl-3 col-12 me-5">
                          <label>Full Name </label>
                          <input
                            type="text"
                            placeholder="Enter Full Name"
                            className={`${style.inputName} ${style.inputNewName} `}
                            value={data.oppositePartyFullName}
                            name="oppositePartyFullName"
                            onChange={(e) => onOppositeClientInfoChange(e,i)}
                          />
                          {data.oppositePartyFullNameError && <div className={style.error}>{data.oppositePartyFullNameError}</div>}
                          {/* {renderErrorMessage("oppositePartyFullName")} */}
                        </div>
                        {/* <div className="col-xl-4 col-12">
                          <label>Business name</label>
                          <input
                            type="text"
                            className={style.inputName}
                            value={oppositePartyBusinessName}
                            name="oppositePartyBusinessName"
                            onChange={(e) => setOppositePartyBusinessName(e.target.value)}
                          />
                          {renderErrorMessage('oppositePartyBusinessName')}
                        </div> */}
                        <div className="col-xl-3 col-12 ms-5 me-5">
                          <label>Advocate name</label>
                          <input
                            type="text"
                            className={`${style.inputName} ${style.inputNewName} `}
                            value={data.oppositeAdvocateName}
                            placeholder="Enter Advocate Name"
                            name="oppositeAdvocateName"
                            onChange={(e) => onOppositeClientInfoChange(e,i)}
                          />
                          {data.oppositeAdvocateNameError && <div className={style.error}>{data.oppositeAdvocateNameError}</div>}
                          {/* {renderErrorMessage("oppositeAdvocateName")} */}
                        </div>
                        < div className="col-xl-3 col-12 ms-5">
                          <label>Mobile Number</label>
                          <input
                            type="text"
                            className={`${style.inputName} ${style.inputNewName} `}
                            value={data.oppositeMobileNumber}
                            placeholder="Enter Mobile No."
                            name="oppositeMobileNumber"
                            onChange={(e) => onOppositeClientInfoChange(e,i)}
                          />
                          {data.oppositeMobileNumberError && <div className={style.error}>{data.oppositeMobileNumberError}</div>}
                          {/* {renderErrorMessage("oppositeMobileNumber")} */}
                        </div>
                        <hr className="fw-bold mt-3 ms-4 col-12"></hr>
                      </div>
                      {i === 0 && 
                        <div className={`${style.marginTopForOppositeClientInputAddIcon} ps-5 col-1`}>
                            <div style={{cursor: "pointer"}}
                              className={`${styleTeamModel.innerTeam} ${styleTeamModel.plusIconBills} ${style.addjustedTopMargin} text-center col-12`}
                              onClick={addMoreOppositeClientDetails}
                            >
                              <IoIosAddCircleOutline />
                            </div>
                        </div>
                      }
                      {i > 0 && 
                        <div className={`${style.marginTopForOppositeClientInputAddIcon} ps-5 col-1`}>
                            <div style={{cursor: "pointer"}}
                              className={`${styleTeamModel.innerTeam} ${styleTeamModel.plusIconBills} ${style.addjustedTopMargin} text-center col-12`}
                              onClick={(e) => removeMoreOppositeClientDetails(i, e)}
                            >
                              <IoIosRemoveCircleOutline />
                            </div>
                        </div>
                      }
                   </div>
                ) )
              : ""}
            </div>
          </div>
        </div>

        {/* <label>Address</label>

        <textarea
          className={style.businessName}
          value={oppositePartyAddress}
          name="oppositePartyAddress"
          onChange={(e) => setOppositePartyAddress(e.target.value)}
          rows="6"
          cols="200"
        ></textarea> */}

        {renderErrorMessage("oppositePartyAddress")}

        <label>Specific Registration Requests /Details</label>
        <input
          type="text"
          className={style.request}
          value={oppositePartySpecificDetails}
          name="oppositePartySpecificDetails"
          placeholder="Enter Specific Registration Requests /Details"
          onChange={(e) => setOppositePartySpecificDetails(e.target.value)}
        />
        {renderErrorMessage("oppositePartySpecificDetails")}

        <h5>Payment</h5>
        <label>Paying Amount</label>
        <input type="number"
         className={`${style.inputName} ${style.inputNewName} `}
         placeholder="Enter Paying Amount"
         value={payingAmount}
         name="payingAmount"
         onChange={(e) => setPayingAmount(e.target.value)}
         />
         {renderErrorMessage("payingAmount")}

        <h5>Additional information</h5>
        <label>would you like to Receive our Monthly e-mail?</label>
        <div>
          <input
            type="radio"
            className="me-2"
            name="receiveEmail"
            value="yes"
            checked={receiveEmail === "yes"}
            onChange={(e) => setReceiveEmail(e.target.value)}
          />
          Yes
          <input
            type="radio"
            className="ms-3 me-2"
            name="receiveEmail"
            value="no"
            checked={receiveEmail === "no"}
            onChange={(e) => setReceiveEmail(e.target.value)}
          />
          No
        </div>
        {renderErrorMessage("receiveEmail")}
        <label>would you like to participate in our client surveys?</label>
        <div>
          <input
            type="radio"
            className="me-2"
            name="participateInSurveys"
            value="yes"
            checked={participateInSurveys === "yes"}
            onChange={(e) => setParticipateInSurveys(e.target.value)}
          />
          Yes
          <input
            type="radio"
            className="ms-3 me-2"
            name="participateInSurveys"
            value="no"
            checked={participateInSurveys === "no"}
            onChange={(e) => setParticipateInSurveys(e.target.value)}
          />
          No
        </div>
        {renderErrorMessage("participateInSurveys")}
        <div className="d-flex  aling-items-center">
          <input type="submit" className={style.submitBtn} />
        </div>
      </form>
    </div>
  );
};

export default AddNewClints;
