/* eslint-disable no-unused-vars */
// import React, { useEffect, useState } from "react";
import React, { useState } from "react";
// import axios, { Axios } from "axios";
import axios from "axios";
import style from "../Style/MainTeamMember.module.css";
// eslint-disable-next-line no-unused-vars
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";

import * as ReactBootStrap from "react-bootstrap";
import '../Style/Loading.module.css'

// import { useNavigate, useParams } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import { async } from "plugins/iterators";
import { DOWNLOAD_DOC_URL, GENERATE_DOC_URL } from "../config/config";

const AddNewDocumentManagers = ({colClass}, {showListDiv}) => {
  const [dataDoc, setDataDoc] = useState([]);
  const [formatDoc, setFormatDoc] = useState([]);
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isListDivShow, setIsListDivShow] = useState(showListDiv);

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();


  const validatForm = () => {
    let errors = {};
    if(dataDoc.length === 0) {
      errors.dataDoc = "Please upload excel file.";
    }

    if(formatDoc.length === 0) {
      errors.formatDoc = "Please upload doc file.";
    }
    return Object.keys(errors).length === 0;
  }


  const uploadFiles = () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    console.log(user,'00000000000000');
    if(!user){
      navigator('/')
    }

    const isFormValid = validatForm();

    let formData = new FormData();
    formData.append('dataDoc', dataDoc[0]);
    formData.append('formatDoc', formatDoc[0]);
    formData.append('userCode', user.id);
    console.log('formData: ', formData, JSON.stringify(formData));
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    if(isFormValid){
      setIsLoading(true);
          axios
            .post(GENERATE_DOC_URL, 
            formData)
            .then((res) => {
              console.log(res);
              if (res.data.Status === 200) {
                console.log("file is uploaded successfully");
                setIsLoading(false);
                swalWithBootstrapButtons.fire({
                  title: "Success!",
                  text: res.data.Message,
                  icon: "success",
                }).then(res => {
                  if(res){
                    navigate('/DocumentManager')
                    setShowDownloadBtn(true);
                  }
                });
              } else {
                console.error("Error", res);
                setIsLoading(false);
                swalWithBootstrapButtons.fire({
                  title: "Error",
                  text: res.data.Message,
                  icon: "error",
                }).then( () => {
                  setShowDownloadBtn(false);
                });
                
              }
            })
            .catch((err) => {
              console.log("upload request failed:", err);
  
              swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your files are not Uploaded.",
                icon: "error",
              });
            });      
    }else{
      swalWithBootstrapButtons.fire({
            title: "Error",
            text: "Please upload Doc or Excel file",
            icon: "error",
          });
    }
  }


  const cancelCaseHistoryEdits = () => {
    window.location.reload();
  };
  

  const downloadUploadedFiles = () => {
    // TODO download functions
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const user = JSON.parse(localStorage.getItem('userData'));
    if(!user){
      navigator('/')
    }
    setIsLoading(true);
    // navigate(`document/download?id=${user.id}`);
    axios({
      url: `${DOWNLOAD_DOC_URL}${user.id}`,
      method: "GET",
      responseType: 'blob',
    }).then(async (res) => {
      if(res?.Message){
        alert(res.Message);
      }else{
        console.log(res);
        setIsLoading(false);
        fileDownload(res.data, 'filename.zip');
        swalWithBootstrapButtons.fire({
          title: "Success!",
          text: "Your file is downloded.",
          icon: "success",
        }).then(res => {
          if(res){
            cancelCaseHistoryEdits();
          }
        });
        // alert('Your File Downloaded.')
      }
    })

    // fetch(`http://localhost:9000/document/download?id=${user.id}`, {
    //     method: "GET",
    //     responseType: 'blob',
    //   })
    //     .then(async (res) => {
    //       if(res?.Message){
    //         alert(res.Message);
    //       }else{
    //         console.log(res);
    //         fileDownload(res.data, 'filename.zip')
    //         alert('Your File Downloaded.')
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("Record not found");
    //     });
  };

  return (
    <div className={`${style.parentDocuments}`}>

      {isLoading && <div className={`${style.modal}`} tabIndex="-1">
        <div className="modal-dialog">
          <div className={`modal-content ${style.modalContent} ${style.topDivForLoader}`}>
            <div className={` ${style.teamForm} mt-3 modal-body`}>
              <div className="d-flex">
                <div>
                  Please Wait...
                </div>
                <div>
                  <ReactBootStrap.Spinner animation="border" size="sm"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className={`${style.childDocuments}`}>
        <div className="d-flex justify-content-center" style={{padding:"25px 0 0 0"}}>
          <div>
            <label className="pe-2"><b style={{color:"red"}}>*</b>Upload Doc File:</label>
          </div>
          <div>
            <input className="ps-2" type="file" onChange={(e) => {
              console.log('e : ', e.target.files);
              setFormatDoc([e.target.files[0]]);
              console.log('dataDoc : ', e.target.files, formatDoc, JSON.stringify(formatDoc));
            }} accept=".docx" placeholder="Please upload doc file" />
          </div>
        </div>
        <div className="d-flex justify-content-center" style={{padding:"25px 0 0 0"}}>
          <div>
            <label className="pe-2"><b style={{color:"red"}}>*</b>Upload Excel File:</label>
          </div>
          <div>
            <input className="ps-2" type="file" onChange={(e) => {
              console.log('e : ', e.target.files);
              setDataDoc([e.target.files[0]]);
              console.log('dataDoc : ', e.target.files, dataDoc, JSON.stringify(dataDoc));
            }} accept=".xls, .xlsx" placeholder="Please upload doc or excel file" />
          </div>
        </div>
        <div className="justify-content-center d-flex mt-3">
          <div className="m-1">
          <button className={style.btnDocuments} onClick={uploadFiles}>Upload File</button>
          </div>
          {/* {showDownloadBtn && <div className="m-1">
          <button className={style.btnDocuments} onClick={downloadUploadedFiles}>Download File</button>
          </div>} */}
        </div>
      </div>
    </div>
  );
};

export default AddNewDocumentManagers;
