/* eslint-disable no-unused-vars */
// import React, { useEffect, useState } from "react";
import React, { useEffect, useState } from "react";
// import axios, { Axios } from "axios";
import axios from "axios";
import style from "../Style/MainTeamMember.module.css";
// eslint-disable-next-line no-unused-vars
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";

import * as ReactBootStrap from "react-bootstrap";
import '../Style/Loading.module.css'

// import { useNavigate, useParams } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import { async } from "plugins/iterators";
import { DOWNLOAD_DOC_URL, GENERATE_DOC_URL, VIEW_DOCS_URL } from "../config/config";
import moment from "moment/moment";
import { AiOutlineDownload } from "react-icons/ai";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { BiSolidDownload } from "react-icons/bi";
import { BiSolidCloudDownload } from "react-icons/bi";
import { BiDownload } from "react-icons/bi";
import { BiCloudDownload } from "react-icons/bi";
import { CgSoftwareDownload } from "react-icons/cg";
import { BsCloudDownload, BsCloudDownloadFill, BsFillCloudDownloadFill, BsInfoCircle } from "react-icons/bs";
import { GiCloudDownload } from "react-icons/gi";
import { VscDesktopDownload } from "react-icons/vsc";
import { IoIosCloudDownload } from "react-icons/io";
import { Tooltip } from "@material-ui/core";

const DocumentManagers = ({colClass}, {showListDiv}) => {
  const [dataDoc, setDataDoc] = useState([]);
  const [formatDoc, setFormatDoc] = useState([]);
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isListDivShow, setIsListDivShow] = useState(showListDiv);

  const [storedDocuments, setStoredDocuments] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const storedDocumentsRecords = storedDocuments?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(storedDocuments.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  let showInfoIcon = false;


  useEffect(() => {
    getUploadedFiles()
  }, []);


  const validatForm = () => {
    let errors = {};
    if(dataDoc.length === 0) {
      errors.dataDoc = "Please upload excel file.";
    }

    if(formatDoc.length === 0) {
      errors.formatDoc = "Please upload doc file.";
    }
    return Object.keys(errors).length === 0;
  }


  // eslint-disable-next-line no-unused-vars
  const uploadFiles = () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    console.log(user,'00000000000000');
    if(!user){
      navigator('/')
    }

    const isFormValid = validatForm();

    let formData = new FormData();
    formData.append('dataDoc', dataDoc[0]);
    formData.append('formatDoc', formatDoc[0]);
    formData.append('userCode', user.id);
    console.log('formData: ', formData, JSON.stringify(formData));
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    if(isFormValid){
      setIsLoading(true);
          axios
            .post(GENERATE_DOC_URL, 
            formData)
            .then((res) => {
              console.log(res);
              if (res.data.Status === 200) {
                console.log("file is uploaded successfully");
                setIsLoading(false);
                swalWithBootstrapButtons.fire({
                  title: "Success!",
                  text: res.data.Message,
                  icon: "success",
                }).then(res => {
                  if(res){
                    setShowDownloadBtn(true);
                  }
                });
              } else {
                console.error("Error", res);
                setIsLoading(false);
                swalWithBootstrapButtons.fire({
                  title: "Error",
                  text: res.data.Message,
                  icon: "error",
                }).then( () => {
                  setShowDownloadBtn(false);
                });
                
              }
            })
            .catch((err) => {
              console.log("upload request failed:", err);
  
              swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your files are not Uploaded.",
                icon: "error",
              });
            });      
    }else{
      swalWithBootstrapButtons.fire({
            title: "Error",
            text: "Please upload Doc or Excel file",
            icon: "error",
          });
    }
  }


  const cancelCaseHistoryEdits = () => {
    window.location.reload();
  };
  

  const downloadUploadedFiles = (i) => {
    console.log('===============',i);
    // TODO download functions
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const user = JSON.parse(localStorage.getItem('userData'));
    if(!user){
      navigator('/')
    }
    setIsLoading(true);
    // navigate(`document/download?id=${user.id}`);
    axios({
      url: `${DOWNLOAD_DOC_URL}${i}`,
      method: "GET",
      responseType: 'blob',
    }).then(async (res) => {
      if(res?.Message){
        alert(res.Message);
      }else{
        console.log(res);
        setIsLoading(false);
        fileDownload(res.data, i);
        swalWithBootstrapButtons.fire({
          title: "Success!",
          text: "Your file is downloded.",
          icon: "success",
        }).then(res => {
          if(res){
            cancelCaseHistoryEdits();
          }
        });
        // alert('Your File Downloaded.')
      }
    })

    // fetch(`http://localhost:9000/document/download?id=${user.id}`, {
    //     method: "GET",
    //     responseType: 'blob',
    //   })
    //     .then(async (res) => {
    //       if(res?.Message){
    //         alert(res.Message);
    //       }else{
    //         console.log(res);
    //         fileDownload(res.data, 'filename.zip')
    //         alert('Your File Downloaded.')
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("Record not found");
    //     });
  };

  const getUploadedFiles = () => {
    // TODO download functions
    // const swalWithBootstrapButtons = Swal.mixin({
    //   customClass: {
    //     confirmButton: "btn btn-success",
    //     cancelButton: "btn btn-danger",
    //   },
    //   buttonsStyling: false,
    // });
    const user = JSON.parse(localStorage.getItem('userData'));
    if(!user){
      navigator('/')
    }
    setIsLoading(true);
    // navigate(`document/download?id=${user.id}`);
    axios({
      url: `${VIEW_DOCS_URL}`,
      method: "GET",
      // responseType: 'blob',
    }).then(async (res) => {
      if(res?.Message && res.Status !== 200){
        alert(res.Message);
      }else{
        console.log(res,'099999999999999999');

        setStoredDocuments(res.data.data)
        // setIsLoading(false);
        // fileDownload(res.data, 'filename.zip');
        // swalWithBootstrapButtons.fire({
        //   title: "Success!",
        //   text: "Your file is downloded.",
        //   icon: "success",
        // }).then(res => {
        //   if(res){
        //     // cancelCaseHistoryEdits();
        //   }
        // });
        // alert('Your File Downloaded.')
      }
    })

    // fetch(`http://localhost:9000/document/download?id=${user.id}`, {
    //     method: "GET",
    //     responseType: 'blob',
    //   })
    //     .then(async (res) => {
    //       if(res?.Message){
    //         alert(res.Message);
    //       }else{
    //         console.log(res);
    //         fileDownload(res.data, 'filename.zip')
    //         alert('Your File Downloaded.')
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("Record not found");
    //     });
  };

  const truncateText = (text) => {
    showInfoIcon = false;
    let words = text?.trim()?.split(" ");
    let storedWords = "";
    if(words?.length > 5){
     storedWords += words?.slice(0, 5)?.join(" ") + "...";
     showInfoIcon = true;
    }else if((text?.trim()?.slice(0,22))?.length === 22){
      storedWords += text?.trim()?.slice(0,22) + "...";
      showInfoIcon = true;
    }else{
      storedWords += text;
      showInfoIcon = false;
    }
    return storedWords;
  }

  return (
   <div className="col-12">
    <div className="col-12 text-start fw-bold m-3">
        <h3 className="pt-3">Documents ({storedDocuments?.length || 0})</h3>
    </div>
    {
      storedDocuments ? 
      <div className={style.innerTeam}>
        <div className={style.teamhead}>
          <h5 className={colClass === "col-12" ? style.id : style.id2}>No</h5>
          <h5
            className={
              colClass === "col-12" ? style.detailsName : style.detailsName2
            }
          >
            Date
          </h5>
          <h5
            className={
              colClass === "col-12" ? style.detailsemail : style.detailsemail2
            }
          >
            File Name
          </h5>
          <h5
            className={
              colClass === "col-12" ? style.detailsAction : style.detailsAction2
            }
          >
            Action
          </h5>
        </div>
        <hr className="m-0" />

        {storedDocumentsRecords && storedDocuments.length > 0
          ? storedDocumentsRecords.sort((b,a) => a.date-b.date).map(
              (teamMember, index) => (
                // console.log(teamMember._id)
                (
                  <div key={index} className={style.teamDetails}>
                    <p
                      className={
                        colClass === "col-12" ? style.paraId : style.paraId2
                      }
                    >
                      {index + 1}
                    </p>
                    <p
                      className={
                        colClass === "col-12"
                          ? style.paraDetailsName
                          : style.paraDetailsName2
                      }
                    >
                      {moment(teamMember.date).format('DD-MMM-YYYY')}
                    </p>
                    <p
                      className={
                        colClass === "col-12"
                          ? style.paraDetailsemail
                          : style.paraDetailsemail2
                      }
                    >
                      {truncateText(teamMember.fileName || "")}
                      {showInfoIcon && <Tooltip placement="top" title={teamMember.fileName}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
                    </p>
                    <p
                      className={
                        colClass === "col-12"
                          ? style.IconDeatails
                          : style.IconDeatails2
                      }
                    >
                      <span
                        className={style.editIcon}
                        onClick={ e => {
                          downloadUploadedFiles(teamMember.fileName)
                        }}
                      >
                          <Tooltip placement="top" title="Download File" >
                            <i><BiSolidDownload /></i>  
                          </Tooltip>                       
                      </span>
                    </p>
                  </div>
                )
              )
            )
          : 
          <div className="col-12 row text-center d-flex justify-content-center">No Documents are Available</div>
          }
      </div>
      : <div className="col-12 row text-center d-flex justify-content-center">No Documents are Available</div>
    }
    {
      storedDocuments && 
      <nav className="float-right p-2">
    <ul className="pagination">
      <li className="page-item">
        <a
          href="reactjs:;"
          className="page-link"
          style={{ color: "gray" }}
          onClick={prePage}
        >
          Prev
        </a>
      </li>
      {numbers.map((n, i) => (
        <li
          className={`page-item ${currentPage === n ? "active" : ""}`}
          key={i}
        >
          <a
            href="reactjs:;"
            className="page-link"
            style={{ color: `${currentPage === n ? "black" : "gray"}` }}
            onClick={() => changeCPage(n)}
          >
            {n}
          </a>
        </li>
      ))}
      <li className="page-item">
        <a
          href="reactjs:;"
          className="page-link"
          style={{ color: "gray" }}
          onClick={nextPage}
        >
          Next
        </a>
      </li>
    </ul>
  </nav>
    }
   </div>
  );
  

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
};

export default DocumentManagers;
