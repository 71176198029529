const { PORTAL_BASE_URL } = require("../environment");

module.exports = {

    //CLIENT PORTAL BASE URL
    TEAM_VIEW_URL : `${PORTAL_BASE_URL}/Team/TeamDataView`,
    CLIENT_VIEW_BY_ID_URL : `${PORTAL_BASE_URL}/client/viewClientfind?id=`,
    CLIENT_VIEW_URL : `${PORTAL_BASE_URL}/client/viewClient`,
    UI_CONFIG_URL : `${PORTAL_BASE_URL}/Team/uiConfig`,
    ADD_CLIENT_URL : `${PORTAL_BASE_URL}/client/addClient`,
    UPDATE_CLIENT_URL : `${PORTAL_BASE_URL}/client/updateClient?id=`,
    VIEW_ADVOCATE_CLIENT_URL : `${PORTAL_BASE_URL}/client/viewClientfind?advocateId=`,
    GENERATE_DOC_URL : `${PORTAL_BASE_URL}/document/generateDoc`,
    DOWNLOAD_DOC_URL : `${PORTAL_BASE_URL}/document/download?id=`,
    VIEW_DOCS_URL : `${PORTAL_BASE_URL}/document/viewDocuments`,
    VIEW_TODO_URL : `${PORTAL_BASE_URL}/todo/viewTodo`,
    TEAM_UPDATE_URL : `${PORTAL_BASE_URL}/Team/TeamDataUpadate?id=`,
    TEAM_ADD_URL : `${PORTAL_BASE_URL}/Team/TeamAddData`,
    BILL_VIEW_URL : `${PORTAL_BASE_URL}/bill/viewBillFind`,
    VIEW_BILLS_URL : `${PORTAL_BASE_URL}/bill/viewBill`,
    ADD_BILLS_URL : `${PORTAL_BASE_URL}/bill/addBill`,
    UPDATE_BILLS_URL : `${PORTAL_BASE_URL}/Team/updateBill?id=`,
    DELETE_BILLS_URL : `${PORTAL_BASE_URL}/Team/TeamDataDelete?id=`,
    FIND_BILL_VIEW_URL : `${PORTAL_BASE_URL}/bill/viewBillFind?id=`,
    VIEW_CASE_URL : `${PORTAL_BASE_URL}/case/viewCase`,

    UPDATE_CASE_URL : `${PORTAL_BASE_URL}/case/updateCase?id=`,
    UPDATE_CASE_FOR_APPEARENCE_URL : `${PORTAL_BASE_URL}/case/updateCaseForAppearence?id=`,
    DELETE_CASE_URL : `${PORTAL_BASE_URL}/case/deleteCase?id=`,
    CLOSED_CASE_URL : `${PORTAL_BASE_URL}/client/closeCase?id=`,
    VIEW_CALENDER_REMINDER_URL : `${PORTAL_BASE_URL}/case/viewCalenderReminder`,
    GENERATE_OTP_URL : `${PORTAL_BASE_URL}/Team/generateOTP`,
    VERIFY_OTP_URL : `${PORTAL_BASE_URL}/Team/verifyOTP`,
    RESET_PASSWORD_URL : `${PORTAL_BASE_URL}/Team/resetPassword`,
    LOGIN_URL : `${PORTAL_BASE_URL}/Team/login`,


    NOTIFICATION_TOKEN_ADD_URL : `${PORTAL_BASE_URL}/Team/notificationTokenAdd`,


    ADD_TODO_URL : `${PORTAL_BASE_URL}/todo/addTodo`,
    
    DELETE_CLIENT_URL : `${PORTAL_BASE_URL}/client/deleteClient?id=`,
    STATUS_WORK_CLIENT_URL : `${PORTAL_BASE_URL}/client/StatusWork?id=`,

    UPLOAD_DOC_FILES_CASE : `${PORTAL_BASE_URL}/document/uploadDocs`,
    GET_LIST_OF_FILES_CASE : `${PORTAL_BASE_URL}/document/viewListUploadedDocuments?id=`,
    DOWNLOAD_FILES_CASE : `${PORTAL_BASE_URL}/document/downloadDocs?id=`,
    UPLOAD_BULK_FILES_CASE : `${PORTAL_BASE_URL}/document/extractAddRecords`,

    // for invoices
    VIEW_INVOICES_URL : `${PORTAL_BASE_URL}/invoice/viewInvoice`,
    ADD_INVOICES_URL : `${PORTAL_BASE_URL}/invoice/addInvoice`,
    UPDATE_INVOICES_URL : `${PORTAL_BASE_URL}/invoice/updateInvoice?id=`,
    VIEW_INVOICES_BY_ID_URL : `${PORTAL_BASE_URL}/invoice/viewInvoiceFind?id=`,

}