import React, { useState } from "react";
import NavBar from "../Component/NavBar";
import Darshboardsaid from "../Component/Darshboardside";
import NotepadApp from "../Component/Notepad";

const Notepad = () => {
  const [saidbarColClass, setSaidbarColClass] = useState("col-2");
  const [mainDashboardColClass, setMainDashboardColClass] = useState("col-12");
  const [mainDashboardClass, setMainDashboardClass] = useState("col-10");
  const [activeComponent, setActiveComponent] = useState("Notepad");

  const toggleColClass = () => {
    setSaidbarColClass(saidbarColClass === "col-2" ? "col-1" : "col-2");
    setMainDashboardColClass(
      mainDashboardColClass === "col-12" ? "col-11" : "col-12"
    );
    setMainDashboardClass(mainDashboardClass === "col-10" ? "col-12" : "col-10");
  };
  const toggleActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <>
      <div className="col-12" style={{ overflow: "hidden"}}>
        <div className="col-12">
          <NavBar />
        </div>
        <div className="d-flex col-12">
          <div className={saidbarColClass}>
            <Darshboardsaid
              colClass={saidbarColClass}
              toggleColClass={toggleColClass}
              toggleActiveComponent={toggleActiveComponent}
            />
          </div>
          <div className={mainDashboardClass} style={{ overflow: "scroll", height: "900px" }}>
            {activeComponent === "Notepad" && (
              <NotepadApp colClass={mainDashboardColClass} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notepad;
