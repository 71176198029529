/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import style from "../Style/PdfClient.module.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import img from "../Assent/logo.png";
import dowloadimg from "../Assent/download.png";
import "jspdf-autotable";
import { CLIENT_VIEW_BY_ID_URL, TEAM_VIEW_URL, VIEW_INVOICES_BY_ID_URL, VIEW_INVOICES_URL } from "../config/config";
import { Tooltip } from "@material-ui/core";
import moment from "moment/moment";

const PdfClient = ({ colClass }) => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [Advocate, setAdvocate] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [matchingAdvocate, setMatchingAdvocate] = useState("");
  const [selectedAdvocateId, setSelectedAdvocateId] = useState("");
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log('userData : ' + userData);
    if (userData) {
      setUserData(userData);
    } else {
      alert("Session Expired.");
      navigate("/");
    }
    axios
      .get(`${VIEW_INVOICES_URL}`)
      .then((response) => {
        console.log('response.data.Data : ' + response.data.Data, '********** : ', JSON.stringify(response(response.data.Data)));
        if (Array.isArray(response.data.Data)) {
          setAdvocate(response.data.Data);
        } else {
          console.error("Advocate data is not an array:");
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });
  }, [navigate]);

  useEffect(() => {
    axios
      .get(`${VIEW_INVOICES_BY_ID_URL}${id}`)
      .then((res) => {
        if (res.data.data) {
          setData(res.data.data);
          console.log(res.data.data);
          let Advocateid = res.data.data.Advocate;
          setSelectedAdvocateId(Advocateid);
        } else {
          console.log("Data Not Find");
          return false;
        }
      })
      .catch((err) => console.error(err));
  }, [id]);

  const AdvocateId = selectedAdvocateId;
  const Advocates = Advocate;

  useEffect(() => {
    if (AdvocateId && Advocates) {
      const Advocatename = Advocates.find(
        (advocate) => advocate._id === AdvocateId
      );
      console.log(Advocatename);

      setMatchingAdvocate(Advocatename?.fullname);
    }
  }, [selectedAdvocateId, Advocates, AdvocateId]);

  //PDF Dowloads Code
  const downloadFile = () => {
    const input = document.getElementById("data");
    if (!input) return;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      console.log(canvas);

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const title = "Invoice History Report";
      const headers = [
        ["NO", "DESCRIPTION", "AMOUNT",  "FINAL AMOUNT", "WORD", "AUTHORIZATION SIGN"],
      ];

      const data1 = data.invoiceHistory.map((elt, i) => [
        i + 1,
        elt.description,
        elt.amount,
        elt.finalAmount,
        elt.word,
        elt.authorizedSign,
      ]);

      let content = {
        startY: 50,
        head: headers,
        body: data1,
      };

      pdf.text(title, 40, 40);
      pdf.autoTable(content);
      pdf.save("InvoiceReportHistory.pdf");   

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("InvoiceMISReport.pdf");
    });
  };

  return (
    <>
      <div className="col-12 d-flex">
        <div id="data" className={`${style.pdf} ${colClass === 'col-12' ? 'col-9' : 'col-9'}`}>
          <div className="col-12 d-flex justify-content-center">
            <h2 className={`m-2 ms-0 ${style.pdfh2}`}>Invoice</h2>

            <img src={img} width="80px" height="60px" alt="" />
          </div>

          <h5 className={style.party}>Invoice Details</h5>

          <div id="data">
            <table
              style={{ borderCollapse: "collapse", border: "0px solid black" }}
              className="justify-content-center d-flex"
            >
              <tbody className={style.tbody}>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td style={{width:"250px"}} className={`align-items-center ${style.clientname}`}>
                   Client Name :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data?.clientName ? data?.clientName : "Loading..."}
                   
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    Invoice No :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data.invoiceNo ? data.invoiceNo : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    CNR No :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data.cnrNo ? data.cnrNo : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    Agreement No :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data.agreementNo ? data.agreementNo : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    Mobile No :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data.mobileNo ? data.mobileNo : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    Full Case No :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data.fullCaseNo ? data.fullCaseNo : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    Date :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data.date ? moment(data?.date).format(
                                "DD-MMM-YYYY") : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    Amount :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data.finalPayment ? data.finalPayment : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td className={`align-items-center ${style.clientname}`}>
                    Payment Mode :
                  </td>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data?.finalPayment > data?.partPayment ? 'Part Pay' : data && data?.finalPayment == data?.partPayment ? 'Final Pay' : "Loading..."}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`${style.dowloadimgwidth} ${
            colClass === "col-12" ? "col-3" : "col-3"
          } d-flex justify-content-center align-items-center`}
        >
          <Tooltip placement="top" title="Download File" >
            <img
              src={dowloadimg}
              className={style.dowloadimg}
              onClick={downloadFile}
              alt="100"
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default PdfClient;
