/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosAddCircleOutline } from "react-icons/io";
import style from "../Style/MainTeamMember.module.css";
import styleAddClient from "../Style/AddNewClient.module.css";
import Modal from "../Component/Modal";
// eslint-disable-next-line no-unused-vars
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
// eslint-disable-next-line no-unused-vars
import { async } from "plugins/iterators";
import { Tooltip } from "@material-ui/core";
import { BsInfoCircle } from "react-icons/bs";
import { CLOSED_CASE_URL, TEAM_ADD_URL, TEAM_UPDATE_URL, UI_CONFIG_URL, UPDATE_CLIENT_URL, VIEW_CASE_URL, VIEW_TODO_URL } from "../config/config";
import moment from "moment";
import { NavItem } from "react-bootstrap";

const MyDiaryCases = ({ colClass }) => {
  const navigate = useNavigate();

// eslint-disable-next-line no-unused-vars
  const [hearingDate, setHearingDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [spacialStage, setSpecialStage] = useState("");

// eslint-disable-next-line no-unused-vars
  const [remarks, setRemarks] = useState("");

  const [search, setSearch] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [showClientInfoDetail, setShowClientInfoDetail] = useState(false);
  const [caseHistory, setCaseHistory] = useState([]);
  const [teamCases, setTeamCases] = useState([]);
  const [teamCasesList, setTeamCasesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputValue, setNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [errorFullName, setErrorFullName] = useState("");
  const [errorMobileNumber, setErrorMobileNumber] = useState("");
  const [errorEmailAddress, setErrorEmailAddress] = useState("");
  const [errorDesignation, setErrorDesignation] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedMember, setSelectedMember] = useState(null);

  const [courtTypesOptions, setCourtTypesOptions] = useState([]);
  const [courtCampusOptions, setCourtCampusOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [eid, setId] = useState("");
  // const teamMembersList = teamMembers;
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = teamCases?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(teamCases.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [memberGroup, setMemberGroup] = useState([]);

  let showInfoIcon = false;

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    getUiConfig();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VIEW_CASE_URL}`,
      headers: { "content-type": "application/json" },
    };

    axios(config)
      .then((res) => {
        console.log("res : ", res);
        let mainRecord = res.data.data;
        mainRecord.forEach( elem => {
          elem['year'] = new Date(elem.date).getYear()
        })
        // mainRecord = mainRecord.filter( elem => elem.advocateId===userData.id || elem.advocateName === userData.id || elem.Advocate === userData.id);
        setTeamCases(mainRecord);
        setTeamCasesList(mainRecord);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getList = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VIEW_CASE_URL}`,
      headers: { "content-type": "application/json" },
    };

    axios(config)
      .then((res) => {
        let mainRecord = res.data.data;
        mainRecord.forEach( elem => {
          elem['year'] = new Date(elem.date).getYear()
        })
        // mainRecord = mainRecord.filter( elem => elem.advocateId===userData.id || elem.advocateName === userData.id || elem.Advocate === userData.id);
        setTeamCases(mainRecord);
        setTeamCasesList(mainRecord);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUiConfig = () => {
    axios
      .get(`${UI_CONFIG_URL}`)
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          const caseOptions = [];
          const courtTypeOptions = [];
          const courtCampusOptions = [];
          response?.data?.data[0]?.caseTypes.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            caseOptions.push(obj);
          });
          response?.data?.data[0]?.courtTypes.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            courtTypeOptions.push(obj);
          });
          response?.data?.data[0]?.courtCampus.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            courtCampusOptions.push(obj);
          });
          setCourtTypesOptions(courtTypeOptions);
          setCourtCampusOptions(courtCampusOptions);
        } else {
          console.error("Advocate data is not an array:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });
  };

  const handleChange = async (e) => {
    // setSearch(e.value);
    const list = teamCasesList;
    console.log(list);
    setTeamCases(list);
    const filteredData = teamCasesList.filter(
      (item) => item.courtType === e.value || item.courtCampusType === e.value
    );
    console.log(teamCases);
    if (e.value) {
      setTeamCases(filteredData);
      // setTeamCasesList(filteredData)
    } else {
      getList();
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredData = teamCasesList.filter(
      (item) =>
      item.clientInfo.find( elem => (elem.clientFullName.toLowerCase() === e.target.value.toLowerCase()) || elem.clientemail.toLowerCase() === e.target.value.toLowerCase())
      // data[1].clientInfo[0].clientFullName
       ||
        item.caseNo.toLowerCase().includes(e.target.value.toLowerCase())
       ||
       moment(item?.nextDate).format(
        "DD-MMM-YYYY"
      ).toLowerCase().includes(moment(e.target.value).format(
        "DD-MMM-YYYY"
      ).toLowerCase())
       ||
       item.caseHistory.find( elem => (moment(elem?.hearingDate).format(
        "DD-MMM-YYYY"
      ).toLowerCase() === moment(e.target.value).format(
        "DD-MMM-YYYY"
      ).toLowerCase()))
      ||
      item?.fullCaseNo.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (e.target.value) {
      setTeamCases(filteredData);
    } else {
      getList();
      // setTeamMembers(teamMembers);
    }
  };

// eslint-disable-next-line no-unused-vars
  const idbox = (id) => {
    // console.log(id);
    const userid = id;
    setId(userid);
  };
  
// eslint-disable-next-line no-unused-vars
  const openForm = (data) => {
    setMemberGroup(data);
    setShowForm(true);
  };


// eslint-disable-next-line no-unused-vars
  const openDetailForm = (data) => {
    setMemberGroup(data);
    setShowDetailForm(true);
  };

  const openClientInfoDetailForm = (data) => {
    console.log('data', data);
    setMemberGroup(data);
    setShowClientInfoDetail(true);
  };

  const closeClientInfoDetailForm = () => {
    setShowClientInfoDetail(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeForm = () => {
    setShowForm(false);
  };


  const closeDetailForm = () => {
    setShowDetailForm(false);
  };

// eslint-disable-next-line no-unused-vars
  const handleEditItem = (id) => {
    console.log("Editing item with ID:", id);
  };

  const updateMember = (updatedMember) => {
    axios
      .put(
        `${TEAM_UPDATE_URL}${updatedMember._id}`,
        updatedMember
      )
      .then((response) => {
        setTeamCases((prevMembers) =>
          prevMembers.map((member) =>
            member._id === updatedMember._id ? updatedMember : member
          )
        );
        closeForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true;

    if (fullName.trim() === "") {
      setErrorFullName("*Full Name is required");
      formIsValid = false;
    } else {
      setErrorFullName("");
    }

    if (mobileNumber.trim() === "") {
      setErrorMobileNumber("*Mobile Number is required");
      formIsValid = false;
    } else if (!/^\d{10}$/.test(mobileNumber)) {
      setErrorMobileNumber("*Mobile Number must be 10 digits");
      formIsValid = false;
    } else {
      setErrorMobileNumber("");
    }

    const numericValue = inputValue.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setNumber(numericValue);
    }

    if (emailAddress.trim() === "") {
      setErrorEmailAddress("*Email Address is required");
      formIsValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(emailAddress)) {
      setErrorEmailAddress("*Invalid Email Address");
      formIsValid = false;
    } else {
      setErrorEmailAddress("");
    }

    if (designation.trim() === "") {
      setErrorDesignation("*Designation is required");
      formIsValid = false;
    } else {
      setErrorDesignation("");
    }

    if (formIsValid) {
      const addTeamMember = {
        fullName: fullName,
        mobileNumber: mobileNumber,
        emailAddress: emailAddress,
        designation: designation,
      };

      axios
        .post(`${TEAM_ADD_URL}`, addTeamMember)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
      setFullName("");
      setMobileNumber("");
      setNumber("");
      setEmailAddress("");
      setDesignation("");
      closeForm();
      window.location.reload(true);
    }
  };

// eslint-disable-next-line no-unused-vars
  const deleteData = (id,status) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: (status==='CLOSED')? 'Yes, Re-Run it!': "Yes, CLose it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`${CLOSED_CASE_URL}${id}`, {
            status: (status==='CLOSED')? 'RUNNING': 'CLOSED',
          })
          .then((res) => {
            console.log(res);
            if (res.status === '200') {
              getList();
              console.log("Case is Closed successfully");
            } else {
              console.error("Error");
            }
          })
          .catch((err) => {
            console.log("Delete request failed:", err);

            swalWithBootstrapButtons.fire({
              title: "Cancelled",
              text: "Your Status not Changed :)",
              icon: "error",
            });
          });
        getList();
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your file is safe :)",
          icon: "error",
        });
      }
    });

    // axios
    //   .delete(`http://localhost:9000/Team/TeamDataDelete?id=${id}`)
    //   .then((response) => {
    //     console.log(response);
    //     setTeamMembers((prevTeamMembers) =>
    //       prevTeamMembers.filter((member) => member._id !== id)
    //     );
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting data: ", error);
    //   });
  };

// eslint-disable-next-line no-unused-vars
  const caseHistoryHandler = (data, obj) => {
    if (data.length > 0) {
      data.forEach((elem) => {
        elem["caseId"] = obj._id;
        elem["createDate"] = new Date();
      });
      data.sort((b, a) => b.hearingDate.toString() - a.hearingDate.toString());

      setCaseHistory(data);
    } else {
      setCaseHistory([
        {
          hearingDate: "",
          specialStage: "",
          remark: "",
          status: "RUNNING",
          caseId: obj._id,
          createDate: new Date(),
        },
      ]);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const saveBtn = () => {
    console.log(caseHistory);
  };

  const addMoreBills = () => {
    setCaseHistory([
      {
        hearingDate: "",
        specialStage: "",
        remark: "",
        status: "RUNNING",
        caseId: caseHistory[0]?.caseId,
        createDate: new Date(),
      },
      ...caseHistory,
    ]);
  };

  const cancelCaseHistoryEdits = () => {
    window.location.reload();
  };

  const onCaseChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...caseHistory];
    onchangeVal[i][`${name}`] = value;

    setCaseHistory(onchangeVal);
  };

  const handleUpdateCaseHistory = () => {
    const data = teamCasesList.filter(
      (elem) => elem._id === caseHistory[0].caseId
    );

    const formData = {
      date: data[0].date,
      clientFullName: data[0].name,
      clientbusinessName: data[0].bName,
      clientType: data[0].clientType,
      caseNo: data[0].caseNo,
      caseHistory,
      caseType: data[0].caseType,
      courtType: data[0].courtType,
      courtCampusType: data[0].courtCampusType,
      advocateName: data[0].advocateName,
      teamMembers: data[0].teamMembers,
      loanAgrement: data[0].loanAgrement,
      clientaddress: data[0].address1,
      clientstreetAddress: data[0].address2,
      clientstate: data[0].state,
      clientemail: data[0].email,
      mobileNumber: data[0].mobileNo,
      oppositePartyFullName: data[0]?.oppPartyDetails?.oppname,
      oppositePartyBusinessName: data[0]?.oppPartyDetails?.oppbName,
      oppositeAdvocateName: data[0]?.oppPartyDetails?.oppAdvocateName,
      oppositePartyAddress: data[0]?.oppPartyDetails?.address,
      oppositePartySpecificDetails: data[0]?.oppPartyDetails?.details,
      participateOption: data[0]?.oppPartyDetails?.pOption,
      receiveEmail: data[0]?.addInfo?.receiveEmail,
      participateInSurveys: data[0]?.addInfo?.surveys,
      // Advocate: data.,
    };

    console.log(formData);

    axios
      .put(
        `${UPDATE_CLIENT_URL}${caseHistory[0].caseId}`,
        formData
      )
      .then((response) => {
        alert("Successfully updated.");
        navigate("/AllCases");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterByDates = (e) => {
    console.log('e : ', e);
    console.log('e.value', e.target.value);
    const list = teamCasesList;
    console.log(list, "==========================");
    setTeamCases(list);
    let filteredData = [];
    console.log('teamCasesList[0] : ', JSON.stringify(teamCasesList[0]));
    // if(e.value === 'End'){
    //   // End sort logic here
    //   filteredData = teamCasesList.sort((a,b) => moment(a.endDate) < moment(b.endDate));
    //   console.log('filteredData', JSON.stringify(filteredData), "length", filteredData.length);
    // }else if(e.value === 'Start'){
    //   // Start sort logic here
    //   filteredData = teamCasesList.sort((a,b) => moment(a.startDate) < moment(b.startDate));
    //   console.log('filteredData', JSON.stringify(filteredData), "length", filteredData.length);
    // }
    if(e.target.value){
      filteredData = teamCasesList.filter(
        (item) => moment(item?.caseHistory[0]?.hearingDate).format(
          "YYYY-MM-DD"
        ) === e.target.value || moment(item?.nextDate).format(
          "YYYY-MM-DD"
        ) === e.target.value
      );
    }
    console.log('filteredData : ' + filteredData, 'Jsobjects : ' + JSON.stringify(filteredData));
    if (e.target.value) {
      setTeamCases(filteredData);
      // setTeamCasesList(filteredData)
    } else {
      getList();
    }
  }

  const truncateText = (text) => {
    showInfoIcon = false;
    let words = text?.trim()?.split(" ");
    let storedWords = "";
    if(words?.length > 5){
     storedWords += words?.slice(0, 5)?.join(" ") + "...";
     showInfoIcon = true;
    }else if((text?.trim()?.slice(0,10))?.length === 10){
      storedWords += text?.trim()?.slice(0,10) + "...";
      showInfoIcon = true;
    }else{
      storedWords += text;
      showInfoIcon = false;
    }
    return storedWords;
  }

  return (
    <div className="col-12">
      <div className="col-12 text-start fw-bold m-3">
        <h3> Cases ({teamCases?.length || 0})</h3>
      </div>
      <div
        className={`${colClass} d-flex mt-1 justify-content-end ${style.teamMember}`}
        style={{ marginBottom: "-20px" }}
      >
        <div className="d-flex justify-content-end">  
          <div className="col-8 row pt-1">
            <input type="date"
              className={`col-12 ${styleAddClient.inputName}`}
              name="date" onChange={(e) => {
              console.log('e : is changed : ' + e);
                filterByDates(e);
              }} 
              placeholder="Filter By " 
              />
          </div>
        </div>
        {/* <div className={style.header}> */}
        {/* <div className={style.headerRight}> */}
        <div>
          <button
            className={style.btnForFilter}
            style={{ width: "300px", padding: "0px", marginBottom: "0px" }}
          >
            <Select
              // className={`col-xl-16 col-24 ${style.Advocate}`}
              style={{ width: "400px" }}
              placeholder="Filter By Court"
              name="selectedAdvocate"
              options={courtTypesOptions}
              onChange={(e) => {
                handleChange(e);
              }}
            ></Select>
          </button>
        </div>

        {/* <div>
          <button
            className={style.btnForFilter}
            style={{ width: "350px", padding: "0px", marginBottom: "0px" }}
          >
            <Select
              // className={`col-xl-16 col-24 ${style.Advocate}`}
              style={{ width: "auto" }}
              placeholder="Filter By Court Campus"
              name="selectedAdvocate"
              options={courtCampusOptions}
              onChange={(e) => {
                handleChange(e);
              }}
            ></Select>
          </button>
        </div> */}

        <div>
          <form className="d-inline">
            <input
              type="text"
              value={search}
              
              onChange={(e) => {
                handleSearch(e);
              }}
              className={style.searchInput}
            />
            <a href="reactjs:;" className={style.searchBtn}>Search</a>
          </form>
        </div>
      </div>
      {/* </div></div> */}

      {caseHistory.length === 0 ? (
        <div
          className={`${colClass} ${style.overFlowx} m-2`}
        >
          {/* <div className={style.header}>
        <h3> Team Mambers ({teamMembers?.length || 0})</h3>
        <div className={style.headerRight}>
          <button className={style.btn} onClick={openForm}>
            <IoIosAddCircleOutline /> Add Members
          </button>
          <form className="d-inline">
            <input
              type="text"
              value={search}
              onChange={handleChange}
              className={style.searchInput}
            />
            <a className={style.searchBtn}>Search</a>
          </form>
        </div>
      </div> */}
          <div
            className={style.innerTeam}
            style={{ display: "table", overflow: "scroll" }}
          >
            <div className={style.teamhead}>
              <h5 className={colClass === "col-10" ? style.id : style.id2}>
                No
              </h5>
              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsNumber
                    : style.detailsNumber2
                }
              >
                PREVIOUS DATE
              </h5>
              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsNumber
                    : style.detailsNumber2
                }
              >
                COURT NAME
              </h5>
              {/* <h5
                className={
                  colClass === "col-10" ? style.detailsName : style.detailsName2
                }
              >
                CASE TYPE
              </h5> */}
              {/* <h5
                className={
                  colClass === "col-10" ? style.detailsName : style.detailsName2
                }
              >
                CASE NO.
              </h5> */}
              {/* <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                YEAR
              </h5> */}
              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                FULL CASE NO.
              </h5>
              {/* <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                CNR NO.
              </h5> */}
              <h5
                 className={
                  style.oppositeFullName
                }
              >
                {/* COURT */}
                FIRST PARTY NAME
              </h5>
              <h5
                className={
                  style.oppositeFullName
                }
              >
                {/* COURT CAMPUS */}
                OPPOSITE PARTY NAME
              </h5>
              {/* <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                ADVOCATE
              </h5> */}
              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                SPECIAL STAGE
              </h5>
              {/* <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                CASE STATUS
              </h5> */}
              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsNumber
                    : style.detailsNumber2
                }
              >
                NEXT DATE
              </h5>
            </div>
            <hr className="m-0" />

            {records.length > 0 && records
              ? records.map(
                  (teamMember, index) => (
                    // console.log(teamMember._id)
                    (
                      <div key={index} className={style.teamDetails}>
                        <p
                          className={
                            colClass === "col-10" ? style.paraId : style.paraId2
                          }
                        >
                          {index + 1}
                        </p>
                        <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsMobile
                              : style.paraDetailsMobile2
                          }
                        >
                          {(teamMember?.caseHistory[0]?.hearingDate) ?  moment(teamMember?.caseHistory[0]?.hearingDate).format(
                                "DD-MMM-YYYY"
                              ) : ""}
                        </p>
                        <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsMobile
                              : style.paraDetailsMobile2
                          }
                        >
                          {truncateText(teamMember?.courtType || "")}
                        {showInfoIcon && <Tooltip placement="top" title={teamMember?.courtType}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
                        </p>
                        {/* <p
                        className={
                          colClass === "col-10"
                            ? style.paraDetailsName
                            : style.paraDetailsName2
                        }
                      >
                        {truncateText(teamMember.clientType || "")}
                        {showInfoIcon && <Tooltip placement="top" title={teamMember?.clientType}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
                      </p> */}
                        {/* <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsName
                              : style.paraDetailsName2
                          }
                        >
                          {teamMember.caseNo}
                        </p> */}
                        {/* <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsDesignation
                              : style.paraDetailsDesignation2
                          }
                        >
                          {1900 + teamMember.year}
                        </p> */}
                        <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsDesignation
                              : style.paraDetailsDesignation2
                          }
                        >
                          {teamMember.fullCaseNo}
                        </p>
                        {/* <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsDesignation
                              : style.paraDetailsDesignation2
                          }
                        >
                          {teamMember.cnrNo}
                        </p> */}
                        {/* <p
                        className={
                          colClass === "col-10"
                            ? style.paraDetailsemail
                            : style.paraDetailsemail2
                        }
                      >
                        {truncateText(teamMember.courtType || "")}
                        {showInfoIcon && <Tooltip placement="top" title={teamMember?.courtType}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
                      </p> */}
                      <p
                          className={
                            style.oppositeFullName
                          }
                        >
                          {truncateText(teamMember?.clientInfo[0]?.clientFullName || "")}
                          {showInfoIcon && <Tooltip placement="top" title={teamMember?.clientInfo[0]?.clientFullName}>
                          <i>
                            <BsInfoCircle />
                          </i>
                          </Tooltip>}
                        </p>
                      <p
                        className={
                          style.oppositeFullName
                        }
                      >
                        {truncateText(teamMember?.oppositeClientInfo[0]?.oppositePartyFullName || "")}
                        {showInfoIcon && <Tooltip placement="top" title={teamMember?.oppositeClientInfo[0]?.oppositePartyFullName}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
                      </p>
                      {/* <p
                        className={
                          colClass === "col-10"
                            ? style.paraDetailsDesignation
                            : style.paraDetailsDesignation2
                        }
                      >
                        {truncateText(teamMember.advocateName || "")}
                        {showInfoIcon && <Tooltip placement="top" title={teamMember?.advocateName}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
                      </p> */}
                        <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsDesignation
                              : style.paraDetailsDesignation2
                          }
                        >
                        {truncateText(teamMember?.caseHistory[0]?.specialStage || "")}
                        {showInfoIcon && <Tooltip placement="top" title={teamMember?.caseHistory[0]?.specialStage}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
                        </p>
                        {/* {teamMember.status === "RUNNING" ? (
                          <p
                            className={
                              colClass === "col-10"
                                ? style.paraDetailsDesignation
                                : style.paraDetailsDesignation2
                            }
                            style={{ background: "#90EE90" }}
                          >
                            {teamMember.status}
                          </p>
                        ) : (
                          <p
                            className={
                              colClass === "col-10"
                                ? style.paraDetailsDesignation
                                : style.paraDetailsDesignation2
                            }
                            style={{ background: "#FFCCCB" }}
                          >
                            {teamMember.status}
                          </p>
                        )} */}
                        <p
                          className={
                            colClass === "col-10"
                              ? style.paraDetailsMobile
                              : style.paraDetailsMobile2
                          }
                        >
                          {(teamMember?.nextDate) ? moment(teamMember?.nextDate).format(
                                "DD-MMM-YYYY"
                              ) : ""}
                        </p>
                      </div>
                    )
                  )
                )
              : 
                <div className="col-12 row text-center d-flex justify-content-center">No Records are Available</div>
              }
          </div>
          {/* <button className={style.addmore} onClick={openForm}>
        + Add More
      </button> */}
          {showModal && (
            <Modal
              member={selectedMember}
              updateTeamMember={updateMember}
              onClose={closeModal}
              showModal={showModal}
            />
          )}
          {showForm && (
            <div openForm={showForm} className={style.modal} tabIndex="-1">
              <div className="modal-dialog">
                <div className={`modal-content ${style.modalContent}`}>
                  <div className="modal-header ">
                    <h3 className="modal-title"> Add New Cases Details</h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      onClick={closeForm}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className={` ${style.teamForm} mt-5 modal-body py-0`}>
                    <form onSubmit={handleSubmit}>
                      <label>Full Name</label>
                      <input
                        type="text"
                        name="fullName"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                      <div className={style.errormsg}>{errorFullName}</div>
                      <label>Mobile Number</label>
                      <input
                        type="text"
                        name="mobileNumber"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        maxLength={10}
                      />
                      <div className={style.errormsg}>{errorMobileNumber}</div>

                      <label>Email Address</label>
                      <input
                        type="text"
                        name="emailAddress"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                      />
                      <div className={style.errormsg}>{errorEmailAddress}</div>

                      <label>Designation</label>
                      <input
                        type="text"
                        name="designation"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                      />
                      <div className={style.errormsg}>{errorDesignation}</div>
                      <input type="submit" className={style.btn} />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}

      {showModal && <Modal onClose={closeModal} showModal={showModal} />}
      {showDetailForm && (
        <div openDetailForm={showDetailForm} className={style.modal} tabIndex="-1">
          <div className="modal-dialog">
            <div className={`modal-content ${style.modalContent}`}>
              <div className="modal-header ">
                <h2 className="modal-title"> Cases Details</h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={closeDetailForm}
                  aria-label="Close"
                ></button>
              </div>
              <div className={` ${style.teamForm} mt-5 modal-body py-0`}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "0px solid black",
                    width: "100%",
                  }}
                >
                  <tr style={{ background: "white" }}>
                    <td style={{ background: "gray", color: "white" }}>No.</td>
                    <td style={{ background: "gray", color: "white" }}>
                      Advocate Name
                    </td>
                  </tr>
                  {memberGroup.map((n, i) => (
                    <tr
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{n.label}</td>
                    </tr>
                  ))}
                  {/* <tr style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                    </tr> */}
                  {/* <tr style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                        <td>Centro comercial Moctezuma</td>
                        <td>Francisco Chang</td>
                        <td>Mexico</td>
                    </tr> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

{showModal && <Modal onClose={closeModal} showModal={showModal} />}
              {showClientInfoDetail && (
                <div openClientInfoDetailForm={showClientInfoDetail} className={style.modal} tabIndex="-1">
                  <div className="modal-dialog">
                    <div className={`modal-content ${style.modalContent}`}>
                      <div className="modal-header ">
                        <h2 className="modal-title">Client Details</h2>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          onClick={closeClientInfoDetailForm}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className={` ${style.teamForm} mt-5 modal-body py-0`}>
                        <table
                          style={{
                            borderCollapse: "collapse",
                            border: "0px solid black",
                            width: "100%",
                          }}
                        >
                          <tr style={{ background: "white" }}>
                            <td style={{ background: "gray", color: "white" }}>No.</td>
                            <td style={{ background: "gray", color: "white" }}>
                              {(memberGroup[0].clientFullName !== '' ? 'Client' : 'Advocate')} Name
                            </td>
                          </tr>
                          {memberGroup.map((n, i) => (
                            <tr
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid black",
                              }}
                            >
                              <td>{i + 1}</td>
                              <td>{n.clientFullName}</td>
                            </tr>
                          ))}
                          {/* <tr style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                                <td>Alfreds Futterkiste</td>
                                <td>Maria Anders</td>
                                <td>Germany</td>
                            </tr> */}
                          {/* <tr style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                                <td>Centro comercial Moctezuma</td>
                                <td>Francisco Chang</td>
                                <td>Mexico</td>
                            </tr> */}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

          <nav className="float-right">
            <ul className="pagination">
              <li className="page-item">
                <a
                  href="reactjs:;"
                  className="page-link"
                  style={{ color: "gray" }}
                  onClick={prePage}
                >
                  Prev
                </a>
              </li>
              {numbers.map((n, i) => (
                <li
                  className={`page-item ${currentPage === n ? "active" : ""}`}
                  key={i}
                >
                  <a
                    href="reactjs:;"
                    className="page-link"
                    style={{ color: `${currentPage === n ? "black" : "gray"}` }}
                    onClick={() => changeCPage(n)}
                  >
                    {n}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a
                  href="reactjs:;"
                  className="page-link"
                  style={{ color: "gray" }}
                  onClick={nextPage}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <div className={`${colClass} mt-5 ${style.teamMember}`}>
          <div className={style.innerTeam}>
            <div className={style.teamhead}>
              <h5
                className={
                  colClass === "col-10" ? style.detailsName : style.detailsName2
                }
              >
                No.
              </h5>
              <h5
                className={
                  colClass === "col-10" ? style.detailsName : style.detailsName2
                }
              >
                Date
              </h5>
              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsemail
                    : style.detailsemail2
                }
              >
                Special Stage
              </h5>

              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsemail
                    : style.detailsemail2
                }
              >
                Remarks
              </h5>
              {/* <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                Remark
              </h5> */}
            </div>
            <hr className="m-0" />

            {caseHistory
              ? caseHistory
                  .sort((b, a) => a.hearingDate - b.hearingDate)
                  .map((item, i) => (
                    <div key={i} className={style.teamDetails}>
                      <input
                        // type="number"
                        disabled
                        name="perticular"
                        value={i + 1}
                        // key={perticular}
                        className={
                          colClass === "col-10"
                            ? style.detailsName
                            : style.detailsName2
                        }
                        // placeholder="Add Bill Number"
                        onChange={(e) => onCaseChange(e, i)}
                      />

                      <input
                        // type=""
                        name="hearingDate"
                        value={item.hearingDate}
                        // key={amount}
                        className={
                          colClass === "col-10"
                            ? style.detailsName
                            : style.detailsName2
                        }
                        // placeholder="Add Bill Number"
                        onChange={(e) => onCaseChange(e, i)}
                      />

                      {/* <p
                    className={
                      colClass === "col-10"
                        ? style.paraDetailsDesignation
                        : style.paraDetailsDesignation2
                    }
                  >
                  </p> */}

                      <input
                        // type="number"
                        name="specialStage"
                        value={item?.specialStage}
                        // key={rate}
                        className={
                          colClass === "col-10"
                            ? style.detailsemail
                            : style.detailsemail2
                        }
                        // placeholder="Add Bill Number"
                        onChange={(e) => onCaseChange(e, i)}
                      />

                      <input
                        name="remark"
                        value={item.remark}
                        // key={tax}
                        className={
                          colClass === "col-10"
                            ? style.detailsemail
                            : style.detailsemail2
                        }
                        // placeholder="Add Bill Number"
                        onChange={(e) => onCaseChange(e, i)}
                      />

                      {/* <input
                      // type="number"
                      name="totalAmt"
                      value={bill.totalAmt}
                      disabled
                      // key={totalAmt}
                      className={
                        colClass === "col-10"
                          ? style.detailsDesignation
                          : style.detailsDesignation2
                      }
                      // placeholder="Add Bill Number"
                      onChange={(e) => onBillChange(e, i)}
                    /> */}
                    </div>
                  ))
              : ""}
          </div>
          <div className="d-flex mt-0 col-12">
            <div className="col-3 mt-0"></div>
            <div className="col-9 mt-0">
              <div className={`${style.innerTeam} text-center mt-1 p-3`}>
                <div className="d-flex justify-content-center">
                  <div>
                    <button
                      className={style.btn}
                      onClick={handleUpdateCaseHistory}
                    >
                      Save
                    </button>
                  </div>
                  <div>
                    <button
                      className={style.btn}
                      onClick={cancelCaseHistoryEdits}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className={style.btn} onClick={addMoreBills}>
                      <IoIosAddCircleOutline />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
};

export default MyDiaryCases;
