import React, { useState } from "react";
import NavBar from "../Component/NavBar";
import Darshboardsaid from "../Component/Darshboardside";
// eslint-disable-next-line no-unused-vars
import PdfClient from "../Component/PdfClient";
import BillPdfs from "../Component/BillPdfs";
import mainDashboardStyle from "../Style/MainDashboard.module.css";

const BillPdfsData = () => {
  const [saidbarColClass, setSaidbarColClass] = useState("col-2");
  const [mainDashboardColClass, setMainDashboardColClass] = useState("col-12");
  const [mainDashboardClass, setMainDashboardClass] = useState("col-10");
  const [activeComponent, setActiveComponent] = useState("BillPdfs");

  const toggleColClass = () => {
    setSaidbarColClass(saidbarColClass === "col-2" ? "col-1" : "col-2");
    setMainDashboardColClass(
      mainDashboardColClass === "col-12" ? "col-11" : "col-12"
    );
    setMainDashboardClass(mainDashboardClass === "col-10" ? "col-12" : "col-10");
  };

  const toggleActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <div className="col-12" style={{ overflow: "hidden"}}>
      <div className="col-12">
        <NavBar />
      </div>
      <div className="d-flex col-12">
        <div className={saidbarColClass}>
          <Darshboardsaid
            colClass={saidbarColClass}
            toggleColClass={toggleColClass}
            toggleActiveComponent={toggleActiveComponent}
          />
        </div>
        <div className={`${mainDashboardClass} ${mainDashboardStyle.height900}`}>
          {activeComponent === "BillPdfs" && (
            <BillPdfs colClass={mainDashboardColClass} />
          )}
        </div>
      </div>
    </div>
  );
};
export default BillPdfsData;
