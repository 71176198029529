/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "../Assent/logo.png";
import style from "../Style/AddNewClient.module.css";
import styleTeamModel from "../Style/MainTeamMember.module.css";
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ADD_INVOICES_URL,
  TEAM_ADD_URL,
  UI_CONFIG_URL,
  UPDATE_INVOICES_URL,
  VIEW_CASE_URL,
  VIEW_INVOICES_BY_ID_URL,
} from "../config/config";
import Select from "react-select";
import { Tooltip } from "@material-ui/core";

const InvoiceMaking = ({ colClass }, { showFilterPart }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [date, setDate] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [clientName, setClientName] = useState("");
  const [cnrNo, setCnrNo] = useState("");
  const [address, setAddress] = useState("");
  const [agreementNo, setAgreementNo] = useState("");
  const [fullCaseNo, setFullCaseNo] = useState("");
  const [caseType, setCaseType] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [partPayment, setPartPayment] = useState("");
  const [finalPayment, setFinalPayment] = useState("");
  const [invoiceHistory, setInvoiceHistory] = useState([
    {
      description: "",
          // specialStage: "",
          amount: "",
          fullAmount: "",
          word: "",
          authorizedSign:"",
          createDate: new Date(),
          isStatusUpdated: true,
    }
  ]);
  const [caseTypesOptions, setCaseTypesOptions] = useState([]);
  const [teamCases, setTeamCases] = useState([]);
  const [teamCasesList, setTeamCasesList] = useState([]);
  const [courtTypesOptions, setCourtTypesOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [courtCampusOptions, setCourtCampusOptions] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  useEffect(() => {
    // console.log("id : ", id);
    getUiConfig();
    initOptions();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VIEW_CASE_URL,
      headers: { "content-type": "application/json" },
    };

    axios(config)
      .then((res) => {
        let mainRecord = res.data;
        // mainRecord.data.forEach((elem) => {
        //   elem["year"] = new Date(elem.date).getYear();
        // });
        // mainRecord.data = mainRecord.data.filter(
        //   (item) => item.status === "CLOSED" || item.status === "RUNNING"
        // );
        setTeamCases(mainRecord.data);
        setTeamCasesList(mainRecord.data);
        console.log("teamCasesList : " + JSON.stringify(teamCasesList));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  

  const getUiConfig = () => {
    axios
      .get(UI_CONFIG_URL)
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          const caseOptions = [];
          const courtTypeOptions = [];
          const courtCampusOptions = [];
          response?.data?.data[0]?.caseTypes.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            caseOptions.push(obj);
          });
          response?.data?.data[0]?.courtTypes.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            courtTypeOptions.push(obj);
          });
          response?.data?.data[0]?.courtCampus.forEach((elem) => {
            let obj = {};
            obj["label"] = elem;
            obj["value"] = elem;
            courtCampusOptions.push(obj);
          });
          setCaseTypesOptions(caseOptions);
          setCourtTypesOptions(courtTypeOptions);
          setCourtCampusOptions(courtCampusOptions);
        } else {
          console.error("Advocate data is not an array:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });
  };

  const initOptions = () => {
    if (id != undefined) {
      fetch(`${VIEW_INVOICES_BY_ID_URL}${id}`, {
        method: "GET",
        headers: { "content-type": "application/json" },
      })
        .then(async (res) => {
          if (res.ok) {
            const record = await res.json();
            const addTeamMember = record.data;
            console.log(record.data);
            setClientName(addTeamMember.clientName);
            setMobileNo(addTeamMember.mobileNo);
            setDate(addTeamMember.date);
            setAddress(addTeamMember.address);
            setFullCaseNo(addTeamMember.fullCaseNo);
            setInvoiceNo(addTeamMember.invoiceNo);
            setCnrNo(addTeamMember.cnrNo);
            setCaseType(addTeamMember.caseType);
            setAgreementNo(addTeamMember.agreementNo);
            setPartPayment(addTeamMember.partPayment);
            setFinalPayment(addTeamMember.finalPayment);
            setInvoiceHistory(addTeamMember.invoiceHistory);
          } else {
            console.error("Error fetching data");
          }
        })
        .catch((err) => {
          console.log("Record not found");
        });
    }
  };
  

  const validateForm = () => {
    let errors = { };

    if (date.trim() === "") {
      errors.date = "*date is required";
    } else {
      setDate(date);
    }

    if (agreementNo.trim() === "") {
      errors.agreementNo = "*agreement number is required";
    } else {
      setAgreementNo(agreementNo);
    }

    if(mobileNo.trim() === ""){
      errors.mobileNo = "*mobile number is required";
    }else if(!isValidMobileNumber(mobileNo)){
      errors.mobileNo = "Please enter a 10 digit mobile number";
    }else if(mobileNo !== "" || mobileNo !== undefined){
      setMobileNo(mobileNo);
    }
    
    if (cnrNo.trim() === "") {
      errors.cnrNo = "*cnr number is required";
    } else {
      setCnrNo(cnrNo);
    }
    
    if (caseType.trim() === "") {
      errors.caseType = "*case type is required";
    } else {
      setCaseType(caseType);
    }

    if (fullCaseNo.trim() === "") {
      errors.fullCaseNo = "*full case number is required";
    } else {
      setFullCaseNo(fullCaseNo);
    }
    
    if (invoiceNo.trim() === "") {
      errors.invoiceNo = "*invoice number is required";
    } else {
      setInvoiceNo(invoiceNo);
    }
    
    if (clientName.trim() === "") {
      errors.clientName = "*client name is required";
    } else {
      setClientName(clientName);
    }
    
    
    console.log("errors : ", JSON.stringify(errors));
    setErrorMessages(errors); 
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = validateForm();

    if (formIsValid) {
      const addTeamMember = {
        clientName: clientName,
        mobileNo: mobileNo,
        address: address,
        cnrNo: cnrNo,
        invoiceNo: invoiceNo,
        date: date,
        agreementNo: agreementNo,
        fullCaseNo: fullCaseNo,
        partPayment: partPayment,
        finalPayment: finalPayment,
        caseType: caseType,
        invoiceHistory:invoiceHistory,
      };

      if(id != undefined) {
        updateCreatedInvoices(addTeamMember);
      }else {
        createNewInvoices(addTeamMember);
      }
    }
  };

  const updateCreatedInvoices = (addTeamMember) => {
    axios
        .put(`${UPDATE_INVOICES_URL}${id}`, addTeamMember)
        .then((response) => {
          if(response.data?.StatusCode === 409){
            alert(response.data.Message);
            setClientName(addTeamMember.clientName);
      setMobileNo(addTeamMember.mobileNo);
      setDate(addTeamMember.date);
      setAddress(addTeamMember.address);
      setFullCaseNo(addTeamMember.fullCaseNo);
      setCaseType(addTeamMember.caseType);
      setInvoiceNo(addTeamMember.invoiceNo);
      setCnrNo(addTeamMember.cnrNo);
      setAgreementNo(addTeamMember.agreementNo);
      setPartPayment(addTeamMember.partPayment);
      setFinalPayment(addTeamMember.finalPayment);
      setInvoiceHistory(addTeamMember.invoiceHistory);
          }
          if(response.data?.StatusCode === 200){
            
                alert(response.data.Message);
                navigate("/InvoiceMIS");
                // alert('Successfully Added.')
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setClientName("");
      setMobileNo("");
      setDate("");
      setAddress("");
      setFullCaseNo("");
      setInvoiceNo("");
      setCnrNo("");
      setAgreementNo("");
      setPartPayment("");
      setFinalPayment("");
      setInvoiceHistory([
        {
          description: "",
              // specialStage: "",
              amount: "",
              fullAmount: "",
              word: "",
              authorizedSign:"",
              createDate: new Date(),
              isStatusUpdated: true,
        }
      ])
  }

  const createNewInvoices = (addTeamMember) => {
    axios
        .post(ADD_INVOICES_URL, addTeamMember)
        .then((response) => {
          alert(response.data.Message);
          navigate('/InvoiceMIS');
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
      setClientName("");
      setMobileNo("");
      setDate("");
      setAddress("");
      setFullCaseNo("");
      setCnrNo("");
      setAgreementNo("");
      setPartPayment("");
      setFinalPayment("");
      setInvoiceHistory([
        {
          description: "",
              // specialStage: "",
              amount: "",
              fullAmount: "",
              word: "",
              authorizedSign:"",
              createDate: new Date(),
              isStatusUpdated: true,
        }
      ])
  }
  
  const addMoreClientHistory = () => {
    setInvoiceHistory([
      {
        description: "",
          // specialStage: "",
          amount: "",
          fullAmount: "",
          word: "",
          authorizedSign:"",
        createDate: new Date(),
        isStatusUpdated: true,
      },
      ...invoiceHistory,
    ]);
  };

  const removeMoreClientHistory = (i, e) => {
    console.log("invoiceHistory : ", invoiceHistory);
    if (invoiceHistory.length === 1) {
      alert("Please add some remarks");
    } else {
      invoiceHistory.splice(i, 1);
      setInvoiceHistory([...invoiceHistory]);
    }
  };


  const onClientChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...invoiceHistory];
    onchangeVal[i][`${name}`] = value;
    setInvoiceHistory(onchangeVal);
  };

  
  const isValidMobileNumber = (mobileNumber) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobileNumber);
  };

  const renderErrorMessage = (fieldName) =>
    errorMessages[fieldName] && (
      <div className={style.error}>{errorMessages[fieldName]}</div>
    );

  return (
    <div className="col-12">
      <div className="col-12 d-flex justify-content-center">
        <h4 className="mt-4 m-2 ms-0">Invoice</h4>
        <img src={logo} width="80px" height="60px" alt="" />
      </div>
      <div className="col-12 mt-5 d-flex justify-content-center">
        <div className="col-12 d-flex justify-content-center row p-1">
          <div className="col-xl-4 col-12 row p-1">
            <label>Date</label>
            <input
              type="date"
              className={style.inputName}
              name="date"
              placeholder="Selected Date"
              value={date}
              max={new Date().toISOString().substr(0, 10)}
              onChange={(e) => setDate(e.target.value)}
            />
            {renderErrorMessage("date")}
          </div>

          <div className="col-xl-4 col-12 row p-1">
            <label>Invoice No.</label>
            <input
              type="tel"
              className={style.inputName}
              name="invoiceNo"
              placeholder="Enter Invoice Number"
              value={invoiceNo}
              onChange={(e) => setInvoiceNo(e.target.value)}
            />
            {renderErrorMessage("invoiceNo")}
          </div>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center row p-1">
        <div className="col-xl-4 col-12 row p-1">
          <label>Cnr No.</label>
          <input
            type="tel"
            name="cnrNo"
            value={cnrNo}
            onChange={(e) => setCnrNo(e.target.value)}
            className={style.inputName}
            placeholder="Enter CNR Number"
          />
          {renderErrorMessage("cnrNo")}
        </div>
        <div className="col-xl-4 col-12 row p-1">
          <label>Agreement No.</label>
          <input
            type="tel"
            name="agreementNo"
            value={agreementNo}
            onChange={(e) => setAgreementNo(e.target.value)}
            className={style.inputName}
            placeholder="Enter Agreement Number"
          />
          {renderErrorMessage("agreementNo")}
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center row p-1">
        <div className="col-xl-4 col-12 row p-1">
          <label>Client Name</label>
          <input
            type="text"
            name="clientName"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            className={style.inputName}
            placeholder="Enter Client Name"
          />
          {renderErrorMessage("clientName")}
        </div>
        <div className="col-xl-4 col-12 row p-1">
          <label>Mobile Number</label>
          <input
            type="number"
            name="mobileNo"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            className={style.inputName}
            placeholder="Enter Mobile Number"
          />
          {renderErrorMessage("mobileNo")}
        </div>
      </div>

      <div className="col-12 pe-2 d-flex justify-content-center row p-1">
        <div className="col-xl-8 col-12 row p-1 pe-3">
          <label>Address</label>
          <textarea className={style.businessName} name="address" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Enter Address" rows="6"
          cols="200"></textarea>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center row p-1">
        <div className="col-xl-4 row col-12 row p-1">
          <label>Case Type</label>
          {/* <input
            type="tel"
            name="caseType"
            value={caseType}
            onChange={(e) => setCaseType(e.target.value)}
            className={style.inputName}
            placeholder="Enter Case Type"
          /> */}
          <Select
                className={`col-xl-4 col-12 ${style.Advocate}`}
                defaultValue={caseType}
                name="caseType"
                placeholder="Select Case Type"
                options={caseTypesOptions}
                // isMulti={true}
                onChange={(e) => {
                  console.log("e : ", e);
                  // const selectedName = e.target.value;
                  setCaseType(e.value);
                }}
              ></Select>
          {renderErrorMessage("caseType")}
        </div>
        <div className="col-xl-4 row col-12 row p-1">
          <label>Full Case No.</label>
          <input
            type="tel"
            name="fullCaseNo"
            value={fullCaseNo}
            onChange={(e) => setFullCaseNo(e.target.value)}
            className={style.inputName}
            placeholder="Enter Full Case Number"
          />
          {renderErrorMessage("fullCaseNo")}
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center row p-1">
        <div className="col-xl-4 col-12 row p-1">
          <label>Part Payment</label>
          <input
            type="tel"
            name="partPayment"
            value={partPayment}
            onChange={(e) => setPartPayment(e.target.value)}
            className={style.inputName}
            placeholder="Enter Part Payment"
          />
        </div>
        <div className="col-xl-4 col-12 row p-1">
          <label>Final Payment</label>
          <input
            type="tel"
            name="finalPayment"
            value={finalPayment}
            onChange={(e) => setFinalPayment(e.target.value)}
            className={style.inputName}
            placeholder="Enter Final Payment"
          />
        </div>
      </div>
      <div className="col-12 row p-1 ps-2 pe-2">
      <div className={`${styleTeamModel.innerTeam} col-12 row`}>
                <div className={styleTeamModel.teamhead}>
                  <h5
                    className={` ${colClass === "col-10" ? styleTeamModel.id : styleTeamModel.id2} ${styleTeamModel.invoiceMakingNo}`}
                  >
                    No.
                  </h5>
                  <h5
                    className={`${
                      colClass === "col-10" ? styleTeamModel.detailsDesignation : styleTeamModel.detailsDesignation2
                    } ${styleTeamModel.invoiceMakingDescription}`}
                  >
                    Discription
                  </h5>
                  <h5
                    className={`${
                      colClass === "col-12"
                        ? styleTeamModel.detailsemail
                        : styleTeamModel.detailsemail2
                    } ${styleTeamModel.invoiceMakingAmount}`}
                  >
                    Amount
                  </h5>
                  <h5
                    className={`${
                      colClass === "col-12"
                        ? styleTeamModel.detailsemail
                        : styleTeamModel.detailsemail2
                    } ${styleTeamModel.invoiceMakingFinalAmount}`}
                  >
                    Final Amount
                  </h5>
                  <h5
                    className={`${
                      colClass === "col-12"
                        ? styleTeamModel.detailsemail
                        : styleTeamModel.detailsemail2
                    } ${styleTeamModel.invoiceMakingWord}`}
                  >
                    Word
                  </h5>

                  <h5
                    className={`${colClass === "col-10"
                    ? styleTeamModel.paraDetailsDesignation
                    : styleTeamModel.paraDetailsDesignation2} ${styleTeamModel.caseStatusText} ${styleTeamModel.invoiceMakingAuthorizedSign}`}
                  >
                    Authorized Sign
                  </h5>
                  {/* <h5
                className={
                  colClass === "col-10"
                    ? styleTeamModel.detailsDesignation
                    : styleTeamModel.detailsDesignation2
                }
              >
                Remark
              </h5> */}
                  <Tooltip placement="top" title="Add more fields">
                    <h5
                      className={`ms-2 ${
                        colClass === "col-10" ? styleTeamModel.id : styleTeamModel.id2
                      } ${styleTeamModel.pluseIconHover}`}
                      onClick={addMoreClientHistory}
                      style={{ cursor: "pointer" }}
                    >
                      <IoIosAddCircleOutline />
                    </h5>
                  </Tooltip>
                </div>
                <hr className="m-0" />

                {invoiceHistory
                  ? invoiceHistory
                      .sort((b, a) => a.hearingDate - b.hearingDate)
                      .map((item, i) => (
                        <div key={i} className={styleTeamModel.teamDetails}>
                          <input type="text" className={` ${colClass === "col-10" ? styleTeamModel.id : styleTeamModel.id2} ${styleTeamModel.invoiceMakingNo}`} value={i+1} />

                          <input
                            // type=""
                            name="description"
                            value={item.description}
                            // key={amount}
                            className={`${
                              colClass === "col-10" ? styleTeamModel.detailsDesignation : styleTeamModel.detailsDesignation2
                            } ${styleTeamModel.invoiceMakingDescription}`}
                            placeholder="Add Description"
                            onChange={(e) => onClientChange(e, i)}
                          />

                          {/* <p
                    className={
                      colClass === "col-10"
                        ? styleTeamModel.paraDetailsDesignation
                        : styleTeamModel.paraDetailsDesignation2
                    }
                  >
                  </p> */}

                          <input
                            type="tel"
                            name="amount"
                            value={item?.amount}
                              // key={rate}
                              className={`${
                                colClass === "col-12"
                                  ? styleTeamModel.detailsemail
                                  : styleTeamModel.detailsemail2
                              } ${styleTeamModel.invoiceMakingAmount}`}
                            placeholder="Add Amount"
                            onChange={(e) => onClientChange(e, i)}
                          />
                          <input
                            type="tel"
                            name="finalAmount"
                            value={item?.finalAmount}
                              // key={rate}
                              className={`${
                                colClass === "col-12"
                                  ? styleTeamModel.detailsemail
                                  : styleTeamModel.detailsemail2
                              } ${styleTeamModel.invoiceMakingFinalAmount}`}
                            placeholder="Add Final Amount"
                            onChange={(e) => onClientChange(e, i)}
                          />
                          <input
                            type="tel"
                            name="word"
                            value={item?.word}
                              // key={rate}
                              className={`${
                                colClass === "col-12"
                                  ? styleTeamModel.detailsemail
                                  : styleTeamModel.detailsemail2
                              } ${styleTeamModel.invoiceMakingWord}`}
                            placeholder="Add Word"
                            onChange={(e) => onClientChange(e, i)}
                          />
                          
                              <input
                                type="tel"
                                // type="file"
                                name="authorizedSign"
                                // accept=".png, .jpeg"
                                value={item.authorizedSign}
                                placeholder="Add Authorized Sign"
                                className={`${colClass === "col-10"
                                ? styleTeamModel.paraDetailsDesignation
                                : styleTeamModel.paraDetailsDesignation2} ${styleTeamModel.caseStatusText} ${styleTeamModel.invoiceMakingAuthorizedSign}`}
                                onChange={(e) => onClientChange(e, i)}
                              />

                          { (i === 0 && invoiceHistory.length > 1 && !item?.remark) ? <Tooltip placement="top" title="Remove this Section">
                            <h5 className={`ms-2 ${colClass === "col-10" ? styleTeamModel.id : styleTeamModel.id2} ${styleTeamModel.minusIconMarginTop} ${styleTeamModel.pluseIconHover}`}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              removeMoreClientHistory(i);
                            }}
                            >
                              <IoIosRemoveCircleOutline />
                            </h5>
                          </Tooltip> : <h5 className={`${colClass === "col-10" ? styleTeamModel.id : styleTeamModel.id2} ${styleTeamModel.noBorderAndShadow}`}
                          >
                          </h5>}
                        </div>
                      ))
                  : ""}
              </div>
      </div>
      <div className="col-12 row d-flex justify-content-center">
      <div className="d-flex  aling-items-center">
          <input type="submit" onClick={handleSubmit} className={style.submitBtn} />
        </div>
      </div>
    </div>
  );
};

export default InvoiceMaking;
