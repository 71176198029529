/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { IoIosAddCircleOutline } from "react-icons/io";
import style from "../Style/MainTeamMember.module.css";
import Modal from "../Component/Modal";
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { DELETE_CASE_URL, TEAM_ADD_URL, TEAM_UPDATE_URL, VIEW_TODO_URL } from "../config/config";
import { Tooltip } from "@material-ui/core";
import { BsInfoCircle } from "react-icons/bs";
import Select from "react-select";
import moment from "moment";

const TaskAlltodo = ({ colClass }) => {
  const [search, setSearch] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [teamCases, setTeamCases] = useState([]);
  const [teamCasesList, setTeamCasesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputValue, setNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [errorFullName, setErrorFullName] = useState("");
  const [errorMobileNumber, setErrorMobileNumber] = useState("");
  const [errorEmailAddress, setErrorEmailAddress] = useState("");
  const [errorDesignation, setErrorDesignation] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [eid, setId] = useState("");
  // const teamMembersList = teamMembers;
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = teamCases?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(teamCases.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [courtTypesOptions, setCourtTypesOptions] = useState([
    {
      label: 'Previous Date',
      value: 'Start'
    },
    {
      label: 'Next Date',
      value: 'End'
    }
  ]);

  let showInfoIcon = false;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userData'));
    if(!user){
      navigator('/')
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VIEW_TODO_URL,
      headers: { "content-type": "application/json", 'Authorization':  `Bearer ${user.token}` },
    };

    axios(config)
      .then((res) => {
        let mainRecord = res.data;
        setTeamCases(mainRecord.data);
        setTeamCasesList(mainRecord.data)
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  const getList = () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    if(!user){
      navigator('/')
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VIEW_TODO_URL,
      headers: { "content-type": "application/json" , 'Authorization':  `Bearer ${user.token}`},
    };

    axios(config)
      .then((res) => {
        let mainRecord = res.data;
        console.log(' res : ' + res.data);
        setTeamCases(mainRecord?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

// eslint-disable-next-line no-unused-vars
  const idbox = (id) => {
    // console.log(id);
    const userid = id;
    setId(userid);
  };

// eslint-disable-next-line no-unused-vars
  const openModal = (teamCases) => {
    setShowModal(true);
    setSelectedMember(teamCases);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  // eslint-disable-next-line no-unused-vars
  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const updateMember = (updatedMember) => {
    axios
      .put(`${TEAM_UPDATE_URL}${updatedMember._id}`, updatedMember)
      .then((response) => {
        setTeamCases((prevMembers) =>
          prevMembers.map((member) =>
            member._id === updatedMember._id ? updatedMember : member
          )
        );
        closeForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

// eslint-disable-next-line no-unused-vars
  const handleChange = (e) => {
    setSearch(e.target.value);
    console.log(teamCasesList,'=======================',search);
    const filteredteamCases = teamCasesList.filter((teamCases) =>
    
    teamCases.fullname.toLowerCase().includes(search.toLowerCase())
    );
    if(e.target.value){
        setTeamCases(filteredteamCases);
    }else{
      getList()
      // setTeamMembers(teamMembers);
    }
  };

  const filterByDates = (e) => {
    console.log('e : ', e);
    console.log('e.value', e.target.value);
    const list = teamCasesList;
    console.log(list, "==========================");
    setTeamCases(list);
    let filteredData = [];
    console.log('teamCasesList[0] : ', JSON.stringify(teamCasesList[0]));
    // if(e.value === 'End'){
    //   // End sort logic here
    //   filteredData = teamCasesList.sort((a,b) => moment(a.endDate) < moment(b.endDate));
    //   console.log('filteredData', JSON.stringify(filteredData), "length", filteredData.length);
    // }else if(e.value === 'Start'){
    //   // Start sort logic here
    //   filteredData = teamCasesList.sort((a,b) => moment(a.startDate) < moment(b.startDate));
    //   console.log('filteredData', JSON.stringify(filteredData), "length", filteredData.length);
    // }
    if(e.target.value){
      filteredData = teamCasesList.filter(
        (item) => item.startDate === e.target.value || item.endDate === e.target.value
      );
    }
    console.log('filteredData : ' + filteredData, 'Jsobjects : ' + JSON.stringify(filteredData));
    if (e.target.value) {
      setTeamCases(filteredData);
      // setTeamCasesList(filteredData)
    } else {
      getList();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true;

    if (fullName.trim() === "") {
      setErrorFullName("*Full Name is required");
      formIsValid = false;
    } else {
      setErrorFullName("");
    }

    if (mobileNumber.trim() === "") {
      setErrorMobileNumber("*Mobile Number is required");
      formIsValid = false;
    } else if (!/^\d{10}$/.test(mobileNumber)) {
      setErrorMobileNumber("*Mobile Number must be 10 digits");
      formIsValid = false;
    } else {
      setErrorMobileNumber("");
    }

    const numericValue = inputValue.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setNumber(numericValue);
    }

    if (emailAddress.trim() === "") {
      setErrorEmailAddress("*Email Address is required");
      formIsValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(emailAddress)) {
      setErrorEmailAddress("*Invalid Email Address");
      formIsValid = false;
    } else {
      setErrorEmailAddress("");
    }

    if (designation.trim() === "") {
      setErrorDesignation("*Designation is required");
      formIsValid = false;
    } else {
      setErrorDesignation("");
    }

    if (formIsValid) {
      const addTeamMember = {
        fullName: fullName,
        mobileNumber: mobileNumber,
        emailAddress: emailAddress,
        designation: designation,
      };

      axios
        .post(TEAM_ADD_URL, addTeamMember)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
      setFullName("");
      setMobileNumber("");
      setNumber("");
      setEmailAddress("");
      setDesignation("");
      closeForm();
      window.location.reload(true);
    }
  };

// eslint-disable-next-line no-unused-vars
  const deleteData = (id) => {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, CLose it!"
    }).then((result) => {
      if (result.isConfirmed) {
        axios
      .put(`${DELETE_CASE_URL}${id}`, {isActive: false})
      .then((res) => {
        console.log(res);
        if (res.ok) {
          getList();
          console.log("Case is Closed successfully");
        } else {
          console.error("Error");
        }
      })
      .catch((err) => {
        console.log("Delete request failed:", err);

        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your Status not Changed :)",
          icon: "error"
        });
      });
      getList();
      swalWithBootstrapButtons.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        icon: "success"
      });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your file is safe :)",
          icon: "error"
        });
      }
    })

    // axios
    //   .delete(`http://localhost:9000/Team/TeamDataDelete?id=${id}`)
    //   .then((response) => {
    //     console.log(response);
    //     setTeamMembers((prevTeamMembers) =>
    //       prevTeamMembers.filter((member) => member._id !== id)
    //     );
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting data: ", error);
    //   });
  };

  const truncateText = (text) => {
    showInfoIcon = false;
    let words = text?.trim()?.split(" ");
    let storedWords = "";
    if(words?.length > 5){
     storedWords += words?.slice(0, 5)?.join(" ") + "...";
     showInfoIcon = true;
    }else if((text?.trim()?.slice(0,20))?.length === 20){
      storedWords += text?.trim()?.slice(0,20) + "...";
      showInfoIcon = true;
    }else{
      storedWords += text;
      showInfoIcon = false;
    }
    return storedWords;
  }

  return (
    <div className={`${colClass} mt-5 ${style.teamMember}`}>
      {/* <div className={style.header}>
        <h3> Team Mambers ({teamMembers?.length || 0})</h3>
        <div className={style.headerRight}>
          <button className={style.btn} onClick={openForm}>
            <IoIosAddCircleOutline /> Add Members
          </button>
          <form className="d-inline">
            <input
              type="text"
              value={search}
              onChange={handleChange}
              className={style.searchInput}
            />
            <a className={style.searchBtn}>Search</a>
          </form>
        </div>
      </div> */}
      <div className="text-start fw-bold">
        <h3> Tasks ({teamCases?.length || 0})</h3>
      </div>
      <div className="col-12 row">
        <div className="col-12 d-flex justify-content-end">
          <div className="col-3">
            <input type="date"
              className={`col-12 ${style.date}`}
              name="date" onChange={(e) => {
              console.log('e : is changed : ' + e);
                filterByDates(e);
              }} 
              placeholder="Filter By " />
          </div>
        </div>
      </div>
      <div className={style.innerTeam}>
        <div className={style.teamhead}>
          <h5 className={colClass === "col-10" ? style.id : style.id2}>No</h5>
          <h5
            className={
              colClass === "col-10" ? style.detailsName : style.detailsName2
            }
          >
            CASE NO.
          </h5>
          <h5
            className={
              colClass === "col-10" ? style.detailsemail : style.detailsemail2
            }
          >
            PREVIOUS DATE
          </h5>
          <h5
            className={
              colClass === "col-10" ? style.detailsNumber : style.detailsNumber2
            }
          >
            NEXT DATE
          </h5>
          <h5
            className={
              colClass === "col-10"
                ? style.detailsDesignation
                : style.detailsDesignation2
            }
          >
            ADVOCATE NAME
          </h5>
          <h5
            className={
              colClass === "col-10"
                ? style.detailsDesignation
                : style.detailsDesignation2
            }
          >
            CLIENT NAME
          </h5>
          
          <h5
            className={
              colClass === "col-10"
                ? style.detailsDesignation
                : style.detailsDesignation2
            }
          >
            TITLE
          </h5>
        </div>
        <hr className="m-0" />

        { records.length > 0 ? records.map((teamMember, index) => (
          // console.log(teamMember._id),
          <div key={index} className={style.teamDetails}>
            <p className={colClass === "col-10" ? style.paraId : style.paraId2}>
              {index + 1}
            </p>
            <p
              className={
                colClass === "col-10"
                  ? style.paraDetailsName
                  : style.paraDetailsName2
              }
            >
              {teamMember.caseNo}
            </p>
            <p
              className={
                colClass === "col-10"
                  ? style.paraDetailsemail
                  : style.paraDetailsemail2
              }
            >
              {teamMember.startDate}
            </p>
            <p
              className={
                colClass === "col-10"
                  ? style.paraDetailsMobile
                  : style.paraDetailsMobile2
              }
            >
              {teamMember.endDate}
            </p>
            <p
              className={
                colClass === "col-10"
                  ? style.paraDetailsDesignation
                  : style.paraDetailsDesignation2
              }
            >
              {truncateText(teamMember.advocateName)}
              {showInfoIcon && <Tooltip placement="top" title={teamMember?.advocateName}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
            </p>
            <p
              className={
                colClass === "col-10"
                  ? style.paraDetailsDesignation
                  : style.paraDetailsDesignation2
              }
            >
              {truncateText(teamMember.clientName)}
              {showInfoIcon && <Tooltip placement="top" title={teamMember?.clientName}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
            </p>
            
            <p
              className={
                colClass === "col-10"
                  ? style.paraDetailsDesignation
                  : style.paraDetailsDesignation2
              }
            >
              {truncateText(teamMember.title)}
              {showInfoIcon && <Tooltip placement="top" title={teamMember?.title}>
                        <i>
                          <BsInfoCircle />
                        </i>
                        </Tooltip>}
            </p>
          </div>
        )) : 
          <div className="col-12 justify-content-center d-flex row">
            No Records Are Available!
          </div>
        }
      </div>
      {/* <button className={style.addmore} onClick={openForm}>
        + Add More
      </button> */}
      {showModal && (
        <Modal
          member={selectedMember}
          updateTeamMember={updateMember}
          onClose={closeModal}
          showModal={showModal}
        />
      )}
      {showForm && (
        <div openForm={showForm} className={style.modal} tabIndex="-1">
          <div className="modal-dialog">
            <div className={`modal-content ${style.modalContent}`}>
              <div className="modal-header ">
                <h3 className="modal-title"> Add New Team Member Details</h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={closeForm}
                  aria-label="Close"
                ></button>
              </div>
              <div className={` ${style.teamForm} mt-5 modal-body py-0`}>
                <form onSubmit={handleSubmit}>
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="fullName"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <div className={style.errormsg}>{errorFullName}</div>
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    maxLength={10}
                  />
                  <div className={style.errormsg}>{errorMobileNumber}</div>

                  <label>Email Address</label>
                  <input
                    type="text"
                    name="emailAddress"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                  <div className={style.errormsg}>{errorEmailAddress}</div>

                  <label>Designation</label>
                  <input
                    type="text"
                    name="designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                  <div className={style.errormsg}>{errorDesignation}</div>
                  <input type="submit" className={style.btn} />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

<nav className= "float-right">
        <ul className="pagination" >
          <li className="page-item">
            <a href="reactjs:;" className="page-link" style={{color:'gray'}}
            onClick={prePage}>Prev</a>
          </li>
          {
            numbers.map((n, i) => (
              <li className = {`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                <a href="reactjs:;" className="page-link" style={{color: `${currentPage === n ? 'black' : 'gray'}`}}
                onClick={() => changeCPage(n)}>{n}</a>
              </li>
            ))
          }
          <li className="page-item">
            <a href="reactjs:;" className="page-link" style={{color:'gray'}}
            onClick={nextPage}>Next</a>
          </li>
        </ul>
      </nav>
    </div>
  );

  function prePage(){
    if(currentPage !== 1){
      setCurrentPage(currentPage - 1)
    }
  }

  function changeCPage(id){
    setCurrentPage(id)
  }

  function nextPage(){
    if(currentPage !== npage){
      setCurrentPage(currentPage + 1)
    }
  }
};

export default TaskAlltodo;


