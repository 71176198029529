import React, { useState} from "react";
import style from "../Style/LoginPage.module.css";
// import loginPageImg from "../Assent/logning-img.png";
// eslint-disable-next-line no-unused-vars
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import Cookies from 'js-cookie';
import { GENERATE_OTP_URL, LOGIN_URL, RESET_PASSWORD_URL, VERIFY_OTP_URL } from "../config/config";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [isForgotPasswordScreen, setForgotPasswordScreen] = useState(false);
  const [isOtpScreen, setOtpScreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [loginScreenBtn, setLoginScreenBtn] = useState("Login");
  const [isConfirmPasswordScreen, setIsConfirmPasswordScreen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

// eslint-disable-next-line no-unused-vars
  const [enteredEmail, setEnteredEmail] = useState("");


  const validateForm = () => {
    let errors = {};

    if (email.trim() === "") {
      errors.email = "Email is required.";
    } else if (!isValidEmail(email)) {
      errors.email = "Email is not valid.";
    }

    if (password.trim() === "") {
      errors.password = "Password is required.";
    }
    
    if (otp.trim() === "" && isOtpScreen) {
        errors.otp = "Otp is required.";
    }
    
    if (confirmPassword.trim() === "" && isConfirmPasswordScreen) {
        errors.confirmPassword = "Confirm Password is required.";
    }
    
    if (newPassword.trim() === "" && isConfirmPasswordScreen) {
        errors.newPassword = "New Password is required.";
    }

    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  const setForgotPassword = () => {
    
    setForgotPasswordScreen(true);
    setLoginScreenBtn("Send");

  }

 

  const handleSubmit = (e) => {
    console.log("forgot pass :", isForgotPasswordScreen);
    console.log("isotpr :", isOtpScreen);
    console.log("isconfirm :", isConfirmPasswordScreen);
    // navigate("/Dashboard");
    e.preventDefault();
    const isValid = validateForm();
    const req = {
      email,
      password
    }
    // forgot screen logic implemented
    if(isForgotPasswordScreen && !isConfirmPasswordScreen && !isOtpScreen){
      const genOtp = {
        email
      }
      // forgot screen logic
      axios
      .post(GENERATE_OTP_URL, genOtp)
      .then((response) => {
        console.log((response));
        if(response.data.StatusCode === 200){
          alert(response?.data?.Message  || 'Successfully')
          setEnteredEmail(email);
          setOtpScreen(true);
          setForgotPasswordScreen(false);
          setIsConfirmPasswordScreen(false);
          // navigate("/Dashboard");
          return ''
        }
        Swal.fire("Something went wrong, please try again later!");
        
      })
      .catch((error) => {
        Swal.fire("Something went wrong, please try again later!");
      });
      // setOtpScreen(true);
    }
    // otp screen logic implemented
    if(isOtpScreen && !isForgotPasswordScreen &&!isConfirmPasswordScreen){
      // otp screen logic
       const tpScreenReq = {
          otp
       }
      // forgot screen logic
      axios
      .post(VERIFY_OTP_URL, tpScreenReq)
      .then((response) => {
        if(response.data.StatusCode === 200){
          alert(response?.data?.Message  || 'Successfully');
          setOtpScreen(false);
          setForgotPasswordScreen(false);
          setIsConfirmPasswordScreen(true);
          // navigate("/Dashboard");
          return ''
        }
        Swal.fire("Something went wrong, please try again later!");
        
      })
      .catch((error) => {
        Swal.fire("Something went wrong, please try again later!");
      });
    }
    // confirm password logic implemented
    if(isConfirmPasswordScreen && !isForgotPasswordScreen && !isOtpScreen){
      // Confirm password logic
      const tpScreenReq = {
        email,
        password: confirmPassword
     }

    // forgot screen logic
    axios
    .post(RESET_PASSWORD_URL, tpScreenReq)
    .then((response) => {
      if(response.data.StatusCode === 200){
        alert(response?.data?.Message  || 'Successfully');
        setOtpScreen(false);
        setForgotPasswordScreen(false);
        setIsConfirmPasswordScreen(false);
        setLoginScreenBtn("Login");
        // navigate("/Dashboard");
        return ''
      }
      Swal.fire("Something went wrong, please try again later!");
      
    })
    .catch((error) => {
      Swal.fire("Something went wrong, please try again later!");
    });
      setForgotPasswordScreen(false);
      setOtpScreen(false);
      setIsConfirmPasswordScreen(false);
      setLoginScreenBtn("Login");
    }
    // login page logic implemented
    if (isValid && !isForgotPasswordScreen &&!isOtpScreen &&!isConfirmPasswordScreen) {
      axios
      .post(LOGIN_URL, req)
      .then((response) => {
        console.log(response);
        if(response.data.StatusCode === 200){
          const data = JSON.stringify(response.data.Data)
          localStorage.setItem('userData',(data))
          navigate("/Dashboard");
          return ''
        }
        Swal.fire("Wrong Password or Username!");
        
      })
      .catch((error) => {
        Swal.fire("Wrong Password or Username!");
      });
    //  navigate("/Dashboard");
    }


  };

  const renderErrorMessage = (fieldName) =>
    errorMessages[fieldName] && <div className={style.error}>{errorMessages[fieldName]}</div>;

  return (
    <div className="row">
      <div className={`col-lg-6 col-12 p-0`}>
       <div className={style.imagecontent}></div>
      </div>
      <div className={`col-lg-6 col-12 p-0 ${style.leftContent}`}>
        <div className="container" style={{ display: "contents" }}>
          <form onSubmit={handleSubmit} className={style.formContent}>
            {!isForgotPasswordScreen && !isOtpScreen && !isConfirmPasswordScreen && (
              <div>
                <div className={style.input}>
                  <input
                    type="text"
                    placeholder="User id"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {renderErrorMessage("email")}
                </div>
                <div className={style.input}>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {renderErrorMessage("password")}
                </div>
                {/* <div className={style.captcha}>
                  <label className='mb-3'>Captcha</label>
                  <ReCAPTCHA
                    sitekey="6Lec1JUoAAAAANbXKtj9q5xNUcQrXyzftr7eg7YY"
                  />
                </div> */}
              </div>
            )}
            {isForgotPasswordScreen && !isOtpScreen && !isConfirmPasswordScreen && (
              <div>
                <div className={style.input}>
                    <input
                      type="text"
                      placeholder="email id"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {renderErrorMessage("email")}
                  </div>
              </div>
            )}
            {isOtpScreen && !isConfirmPasswordScreen && !isForgotPasswordScreen && (
              <div>
                <div className={style.input}>
                  <input
                    type="password"
                    placeholder="Enter OTP"
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  {renderErrorMessage("otp")}
                </div>
              </div>
            )}
            {isConfirmPasswordScreen && !isForgotPasswordScreen  && !isOtpScreen && (
              <div>
                <div className={style.input}>
                  <input
                    type="password"
                    placeholder="New password"
                    name="newpassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {renderErrorMessage("newPassword")}
                </div>
                <div className={style.input}>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {renderErrorMessage("confirmPassword")}
                </div>
              </div>
            )}
            <div className={style.btnSubmit}>
              <button type="submit" className={style.submitBtn}>{loginScreenBtn}</button>
            </div>
            {!isForgotPasswordScreen && !isConfirmPasswordScreen && !isOtpScreen && (
              <div className="text-center mt-1">
                <a href="reactjs:;" className={style.forgotPassword} onClick={setForgotPassword}>Forgot Password</a>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
