/* eslint-disable no-unused-vars */
import React, {useState } from 'react'
import { FiSearch } from "react-icons/fi";
import setting from '../Assent/settings.png'
// eslint-disable-next-line no-unused-vars
import notification from '../Assent/notification.png'
import style from '../Style/Navbar.module.css'
import logo from '../Assent/logo.png'
import { Popover } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';




const NavBar = () => {
    const navigate = useNavigate();

    const [settingAnchorEl, setSettingAnchorEl] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const openSettingPopover = Boolean(settingAnchorEl);
    // eslint-disable-next-line no-unused-vars
    const openNotificationsPopover = Boolean(notificationAnchorEl);

    const [showNotifications, setShowNotifications] = useState(false);

  // eslint-disable-next-line no-unused-vars
    const [notificationsMessages, setNotificationsMessages] = useState([
        {
            "name": "Priyanka Shaha",
            "message": "Hi, How are you? What about our next meeting",
        },{
            "name": "...leb Flakelar commented on Admin",
            "message": "min ago 1",
        },{
            "name": "Karen Robinson",
            "message": "Wow! this admin looks good and awesome design",
        },
        {
            "name": "Priyanka Shaha",
            "message": "Hi, How are you? What about our next meeting",
        },{
            "name": "...leb Flakelar commented on Admin",
            "message": "min ago 1",
        },{
            "name": "Karen Robinson",
            "message": "Wow! this admin looks good and awesome design",
        },
    ]);

  // eslint-disable-next-line no-unused-vars
    const setNotifications = () => {
        if(showNotifications){
            setShowNotifications(false);
        }else{
            setShowNotifications(true);
        }
    };

  // eslint-disable-next-line no-unused-vars
    const closeForm = () => {
        setShowNotifications(false);
    };

    const handleLogOut = () => {
    //     Cookies.set('userData', {})
    //     const userData = Cookies.get('userData');
    // let key = {}
    // Object.assign(key,JSON.parse(userData))
        navigate("/");
        console.log(',mmmmmmmmmmmmmmmmmmmmm');
    }


    return (
        <>
            <div className="Dashboard-main-top">

                <div className={`${style.DashboardNavarbar} d-flex justify-content-between`}>
                    <div className={`${style.logo}`}>
                        <img src={logo} width="155px" height="115px" alt=''/>
                    </div>

                    {/* <div className={`${style.search} col-xxl-8 col-xl-7 col-lg-7 col-md-6 col-sm-5`}>
                        <FiSearch className={`${style.searchicon} me-3`} />
                        <input className={style.searchinput} type="text" placeholder="Search Here....." />
                    </div> */}

                    <div className={`${style.navabaricon}`}>
                        <a href="reactjs:;" onClick={(event) => {
                    setSettingAnchorEl(event.currentTarget);
                }}>
                            <img src={setting} className={style.settingsicon} width="30px" height="30px" alt='' />
                        </a>
                        {/* popover for the change pin and logout */}
                        <Popover
                            anchorEl={settingAnchorEl}
                            open={openSettingPopover}
                            id={openSettingPopover ? "simple-popover" : undefined}
                            onClose={() => {
                                setSettingAnchorEl(null);
                            }}
                            transformOrigin={{
                                horizontal: "center",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "center",
                                vertical: "bottom",
                            }}
                        >
                            <a href="reactjs:;" className='p-2 m-2 mb-0'>Change Pin</a>
                            <br></br>
                            <a href="reactjs:;" className='p-2 m-2 mt-0' onClick={ handleLogOut }>Logout</a>
                        </Popover>

                        {/* <a href="javascript:void(0)" onClick={(event) => {
                    setNotificationAnchorEl(event.currentTarget);}}  className="ms-3 ms-md-2 ms-sm-0">
                            <img src={notification} className={style.notification} width="30px" height="30px" />
                        </a> */}
                            {/* notifications Popover */}
                        {/* <Popover
                            anchorEl={notificationAnchorEl}
                            open={openNotificationsPopover}
                            id={openNotificationsPopover ? "simple-popover" : undefined}
                            onClose={() => {
                                setNotificationAnchorEl(null);
                            }}
                            transformOrigin={{
                                horizontal: "left",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                            }}
                        >
                            <div className='d-flex justify-content-between'>
                                <p className='p-2 m-2 mb-0'>Notifications</p>
                                <a href="javascript:void(0)" className='p-2 m-2 mb-0'>Clear All</a>
                            </div>
                            <div className={`${ (notificationsMessages.length > 3) ? style.divScrolling : (notificationsMessages.length == 0) ? 'm-1 text-center' : 'p-1'} col-12`}>
                                {(notificationsMessages.length > 0) ? notificationsMessages.map((notification, index) => (
                                                    
                                    <div className={` ${((index+1)%2 === 0) ? style.notificationsBoxShadow1 : style.notificationsBoxShadow2} col-10 ps-5 ms-5`}>
                                        <div className='p-1 m-1'>
                                            {notification.name}
                                        </div>
                                        <div className='p-1 m-1'>
                                            {notification.message}
                                        </div>
                                    </div>
                                ) ) : "No notifications are available"}
                            </div>
                        </Popover> */}
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavBar
