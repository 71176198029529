import React, { useState, useEffect } from "react";
import style from "../Style/PdfClient.module.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import img from "../Assent/logo.png";
import dowloadimg from "../Assent/download.png";
import "jspdf-autotable";
import { CLIENT_VIEW_BY_ID_URL, TEAM_VIEW_URL } from "../config/config";
import { Tooltip } from "@material-ui/core";

const PdfClient = ({ colClass }) => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [Advocate, setAdvocate] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [matchingAdvocate, setMatchingAdvocate] = useState("");
  const [selectedAdvocateId, setSelectedAdvocateId] = useState("");
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log('userData : ' + userData);
    if (userData) {
      setUserData(userData);
    } else {
      alert("Session Expired.");
      navigate("/");
    }
    axios
      .get(`${TEAM_VIEW_URL}`)
      .then((response) => {
        console.log('response.data.Data : ' + response.data.Data, '********** : ', JSON.stringify(response(response.data.Data)));
        if (Array.isArray(response.data.Data)) {
          setAdvocate(response.data.Data);
        } else {
          console.error("Advocate data is not an array:");
        }
      })
      .catch((error) => {
        console.error("Error fetching advocate data:", error);
      });
  }, [navigate]);

  useEffect(() => {
    axios
      .get(`${CLIENT_VIEW_BY_ID_URL}${id}`)
      .then((res) => {
        if (res.data.data) {
          setData(res.data.data);
          console.log(res.data.data);
          let Advocateid = res.data.data.Advocate;
          setSelectedAdvocateId(Advocateid);
        } else {
          console.log("Data Not Find");
          return false;
        }
      })
      .catch((err) => console.error(err));
  }, [id]);

  const AdvocateId = selectedAdvocateId;
  const Advocates = Advocate;

  useEffect(() => {
    if (AdvocateId && Advocates) {
      const Advocatename = Advocates.find(
        (advocate) => advocate._id === AdvocateId
      );
      console.log(Advocatename);

      setMatchingAdvocate(Advocatename?.fullname);
    }
  }, [selectedAdvocateId, Advocates, AdvocateId]);

  //PDF Dowloads Code
  const downloadFile = () => {
    const input = document.getElementById("data");
    if (!input) return;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      console.log(canvas);

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const title = "Case History Report";
      const headers = [
        ["DOC NO.", "CASE NO.", "HEARING DATE", "STATUS", "REMARK"],
      ];

      const data1 = data.caseHistory.map((elt, i) => [
        i + 1,
        data.caseNo,
        elt.hearingDate,
        elt.status,
        elt.remark,
      ]);

      let content = {
        startY: 50,
        head: headers,
        body: data1,
      };

      pdf.text(title, 40, 40);
      pdf.autoTable(content);
      pdf.save("report.pdf");

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("client_data.pdf");
    });
  };

  return (
    <>
      <div className="col-12 d-flex">
        <div id="data" className={`${style.pdf} ${colClass === 'col-12' ? 'col-9' : 'col-9'}`}>
          <h2 className={style.pdfh2}>GV LAW FIRM Client Details</h2>

          <img src={img} className={style.imgicon} alt="" />

          <h5 className={style.party}>Party Details</h5>

          <div id="data">
            <table
              style={{ borderCollapse: "collapse", border: "0px solid black" }}
              className="justify-content-center d-flex"
            >
              <tbody className={style.tbody}>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Name :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data && data?.clientInfo?.length > 0 ? data?.clientInfo[0]?.clientFullName : "Loading..."}
                    {/* {
                      data?.clientInfo?.length > 0 && data?.clientInfo ? (
                        data?.clientInfo?.map((clientName,index) => (
                          <span>
                            {clientName?.clientFullName}
                          </span>
                        ))
                      ) : 'Loading...'
                    } */}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Case No :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.caseNo : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Date :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.date : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Case Type :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data?.clientType : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Reference Name :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.bName : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Address :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.address1 : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    State :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.state : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Street Address :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.address2 : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Email Address :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.email : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Phone :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.mobileNo : "Loading..."}
                  </td>
                </tr>

                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Advocate Name :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data.advocateName : "Loading..."}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h5 className={style.opposite}>Opposite Party Details</h5>

          <div id="data">
            <table
              style={{
                borderCollapse: "collapse",
                border: "0px solid black",
                width: "100%",
              }}
              className="justify-content-center d-flex"
            >
              <tbody>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Contact Person :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data?.oppPartyDetails?.oppname : "Loading..."}
                  </td>
                </tr>
                {/* <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Business Name :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data?.oppPartyDetails?.oppbName : "Loading..."}
                  </td>
                </tr>
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <h4 className={`align-items-center ${style.clientname}`}>
                    Address :
                  </h4>
                  <td
                    style={{
                      borderCollapse: "rowapse",
                      border: "1px solid black",
                    }}
                    className={`align-items-center ${style.clientnameP}`}
                  >
                    {data ? data?.oppPartyDetails?.address : "Loading..."}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>

          <h5 className={style.opposite}>History of Case</h5>

          <br></br>

          <div id="data">
            <table
              style={{
                borderCollapse: "collapse",
                border: "0px solid black",
                width: "100%",
              }}
            >
              <tr>
                <th>No.</th>
                <th>Hearing Date</th>
                <th>Status</th>
                <th>Remarks</th>
              </tr>
              {data?.caseHistory?.map((n, i) => (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  <td>{i + 1}</td>
                  <td>{n.hearingDate}</td>
                  <td>{n.status}</td>
                  <td>{n.remark}</td>
                </tr>
              ))}
              {/* <tr style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
        <td>Alfreds Futterkiste</td>
        <td>Maria Anders</td>
        <td>Germany</td>
    </tr> */}
              {/* <tr style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
        <td>Centro comercial Moctezuma</td>
        <td>Francisco Chang</td>
        <td>Mexico</td>
    </tr> */}
            </table>
          </div>
        </div>
        <div
          className={`${style.dowloadimgwidth} ${
            colClass === "col-12" ? "col-3" : "col-3"
          } d-flex justify-content-center align-items-center`}
        >
          <Tooltip placement="top" title="Download File" >
            <img
              src={dowloadimg}
              className={style.dowloadimg}
              onClick={downloadFile}
              alt="100"
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default PdfClient;
