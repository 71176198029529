/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import style from "../Style/MainTeamMember.module.css";
import styleModel from "../Style/MainTeamMember.module.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "./Modal";
import axios from "axios";
import { VIEW_CALENDER_REMINDER_URL, VIEW_CASE_URL } from "../config/config";
import { Tooltip } from "@material-ui/core";
import { BsInfoCircle } from "react-icons/bs";
import { IoIosInformation } from "react-icons/io";

const EventCalendars = ({ colClass }) => {
  // eslint-disable-next-line no-unused-vars
  const calendarRef = React.createRef();
  const [myEventsList, setMyEventsList] = useState([]);
  const [teamCases, setTeamCases] = useState([]);
  const [teamCasesList, setTeamCasesList] = useState([]);
  const [caseHistory, setCaseHistory] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [saveClientDetails, setSaveClientDetails] = useState([]);
  const [showSavedClientDetails, setShowSavedClientDetails] = useState(false);
  const [saveTitle, setSaveTitle] = useState("");
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [showSpecialDetailForm, setShowSpecialDetailForm] = useState(false);
  const [showCalender, setShowCalender] = useState(true);
  const [memberGroup, setMemberGroup] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = caseHistory?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(caseHistory.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [showClientInfoDetail, setShowClientInfoDetail] = useState(false);
  // const colClass = "col-12";
  let showInfoIcon = false;
// eslint-disable-next-line no-unused-vars
  const bcgColor = [
    "light-grey",
    "green",
    "orange",
    "light-yellow",
    "light-blue",
  ];

  const localizer = momentLocalizer(moment);
  // const myEventsList = [
  //   {
  //     title: "My Event",
  //     start: new Date(),
  //     end: new Date(),
  //     advocateName: "Akshay",
  //     clientName: "Raj",
  //     hearingDate: new Date(),
  //     caseNo: 120,
  //     // elcolor:'red'
  //     colorEvento: "light-grey",
  //   },
  //   {
  //     title: "Otro",
  //     start: new Date(),
  //     end: new Date(),
  //     advocateName: "Ashish",
  //     clientName: "Ashu",
  //     hearingDate: new Date(),
  //     caseNo: 120,
  //     colorEvento: "green",
  //     color: "white",
  //   },
  //   {
  //     title: "My New Event",
  //     start: new Date(),
  //     end: new Date(),
  //     advocateName: "Swarajit",
  //     clientName: "Swaru",
  //     hearingDate: new Date(),
  //     caseNo: 120,
  //     // elcolor:'red'
  //     colorEvento: "red",
  //   },
  //   {
  //     title: "New Otro",
  //     start: new Date(),
  //     end: new Date(),
  //     advocateName: "Swarani",
  //     clientName: "Swaruni",
  //     hearingDate: new Date(),
  //     caseNo: 120,
  //     colorEvento: "green",
  //     color: "white",
  //   },
  //   {
  //     title: "My New Again Event",
  //     start: new Date(),
  //     end: new Date(),
  //     advocateName: "Ashu",
  //     clientName: "Ashuti",
  //     hearingDate: new Date(),
  //     caseNo: 120,
  //     // elcolor:'red'
  //     colorEvento: "red",
  //   },
  //   {
  //     title: "New Again Otro",
  //     start: new Date(),
  //     end: new Date(),
  //     advocateName: "Vaishu",
  //     clientName: "Mahi",
  //     hearingDate: new Date(),
  //     caseNo: 120,
  //     colorEvento: "green",
  //     color: "white",
  //   },
  // ];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (!user) {
      navigator("/");
    }

      let newConfig = {
        method: "get",
        maxBodyLength: Infinity,
        url: VIEW_CASE_URL,
        headers: { "content-type": "application/json" },
      };
  
      axios(newConfig)
        .then((res) => {
          let casesMainRecord = res.data.data;
          console.log('mainRecord newly : ', casesMainRecord);
          casesMainRecord.forEach((elem) => {
            elem["year"] = new Date(elem.date).getYear();
          });
          casesMainRecord = casesMainRecord.filter(
            (item) => item.status === "CLOSED" || item.status === "RUNNING"
          );
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            // url: "http://localhost:9000/todo/viewTodo",
            url: VIEW_CALENDER_REMINDER_URL,
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          };
      
          axios(config).then((res) => {
              let mainRecord = res.data.data;
      
              // console.log("localizer : ", localizer);
              console.log('mainRecord : ', mainRecord);
              const dataArr = [];
              mainRecord.forEach((elem) => {
                const obj = {
                  title: "",
                  start: "",
                  end: "",
                  hearingDate: "",
                  caseNo: 0,
                  specialStage: "",
                  remark: "",
                  status: "",
                  caseId: "",
                  createDate: "",
                  color: "white",
                  colorEvento: "",
                };
                obj.start = elem.hearingDate;
                obj.end = elem.hearingDate;
                obj.hearingDate = elem.hearingDate;
                obj.caseNo = elem.caseNo;
                obj.color = "white";
                obj.caseId = elem.caseId;
                obj.createDate = elem.createDate;
                obj.specialStage = elem.specialStage;
                obj.remark = elem.remark;
                obj.status = elem.status;
                const newData = casesMainRecord.filter(ele => ele.caseId === elem.caseId || ele._id === elem.caseId || ele.caseNo === elem.caseId);
                console.log('newData', newData);
                obj.title = newData[0]?.clientInfo[0]?.clientFullName.trim().split(" ")[0]+"/"+newData[0].caseNo+"/"+newData[0].cnrNo;
                console.log('obj.title :', obj.title);
                getTheColorOfTheEvent(elem, obj);
                dataArr.push(obj);
              });
              // console.log("dataArr : ", JSON.stringify(dataArr));
              setMyEventsList(dataArr);
            })
            .catch((error) => {
              console.log(error);
            });
          setTeamCases(casesMainRecord);
          setTeamCasesList(casesMainRecord);
          console.log('teamCasesList : ' + JSON.stringify(teamCasesList));
        })
        .catch((error) => {
          console.log(error);
        });
        
    
  }, []);

  let [value, setValue] = useState([{
    title: "",
    start: new Date(),
    end: new Date(),
  }]);
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);

// eslint-disable-next-line no-unused-vars
  const openModal = (teamCases) => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openform = () => {
    setShowForm(true);
    console.log("showForm :", showForm);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const getTheColorOfTheEvent = (elem, obj) => {
    const currentDate = new Date();
    const eventEndDate = new Date(moment(elem.hearingDate).format("DD-MMM-YYYY"));
    const diffTime = eventEndDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (
      currentDate.getDate() < eventEndDate.getDate() &&
      currentDate.getMonth() + 1 <= eventEndDate.getMonth() + 1 &&
      currentDate.getFullYear() <= eventEndDate.getFullYear()
    ) {
      obj.colorEvento = "red";
  } else if (
      currentDate.getDate() > eventEndDate.getDate() &&
      (currentDate.getMonth() + 1 >= eventEndDate.getMonth() + 1 ||
        currentDate.getMonth() + 1 <= eventEndDate.getMonth() + 1) &&
      (currentDate.getFullYear() >= eventEndDate.getFullYear() ||
        currentDate.getFullYear() <= eventEndDate.getFullYear())
    ) {
      if (diffDays >= 15) {
        obj.colorEvento = "green";
      } else if (diffDays >= 7 && diffDays < 15) {
        obj.colorEvento = "orange";
      }else if (diffDays < 7) {
        obj.colorEvento = "red";
      }
    } else if (
      currentDate.getDate() === eventEndDate.getDate() &&
      currentDate.getMonth() + 1 >= eventEndDate.getMonth() + 1 &&
      (currentDate.getFullYear() >= eventEndDate.getFullYear() ||
        currentDate.getFullYear() <= eventEndDate.getFullYear())
    ) {
      obj.colorEvento = "red";
    } else {
      obj.colorEvento = "red";
    }
    return obj;
  };

  useEffect(() => {}, []);

  const storeDataAndOpenTheForm = (event) => {
    console.log('showCalender : ', showCalender, 'colclass : ', colClass);
    const newData = teamCasesList.filter(ele => ele.caseId === event.caseId || ele._id === event.caseId || ele.caseNo === event.caseId);
    console.log('newData', JSON.stringify(newData));
    setShowCalender(false);
    setClientData(newData[0]);
    setCaseHistory(newData[0].caseHistory);
    openform();
  };

  const truncateText = (text) => {
    showInfoIcon = false;
    let words = text?.trim()?.split(" ");
    let storedWords = "";
    if (words?.length >= 5) {
      storedWords += text?.trim()?.slice(0, 5) + "...";
      showInfoIcon = true;
    } else if (text?.trim()?.slice(0, 30)?.length === 30) {
      storedWords += text?.trim()?.slice(0, 30) + "...";
      showInfoIcon = true;
    } else {
      storedWords += text;
      showInfoIcon = false;
    }
    return storedWords;
  };

  const saveClickedData = (data,name) => {
    console.log('data: ' + data);
    // closeForm();
    setSaveClientDetails([data]);
    setSaveTitle(name);
    setShowSavedClientDetails(true);
    setShowModal(true);
  }

  const closedSavedClickedData = () => {
    setShowSavedClientDetails(false);
    setShowModal(false);
  }

  

  const openClientInfoDetailForm = (data) => {
    console.log("data", data);
    setMemberGroup(data);
    setShowClientInfoDetail(true);
  };

  const closeClientInfoDetailForm = () => {
    setShowClientInfoDetail(false);
  };


  const opendetailform = (data) => {
    console.log("data", data);
    console.log("type : ", typeof data);
    setMemberGroup(data);
    setShowDetailForm(true);
  };

  const closeDetailForm = () => {
    setShowDetailForm(false);
  };

  const openspecialdetailform = (data,name) => {
    console.log("data", data);
    console.log("type : ", typeof data);
    setSaveTitle(name);
    setMemberGroup(data);
    setShowSpecialDetailForm(true);
  };

  const closeSpecialDetailForm = () => {
    setShowSpecialDetailForm(false);
  };

  const cancelCaseHistoryEdits = () => {
    window.location.reload();
  };

  return (
    <div className={style.fullwidth} style={{overflow : "hidden scroll", height:"750px"}}>
      {
        showCalender ? 
        <div className={style.inner}>
        <Calendar
          dragToMove={true}
          dragToResize={true}
          eventOverlap={true}
          exclusiveEndDates ={true}
          localizer={localizer}
          events={myEventsList}
          startAccessor={(event) => { return moment(event.end).toDate(); }}
          endAccessor={(event) => { return moment(event.end).toDate();}}
          style={{ height: 600 }}
          BackgroundWrapper="green"
          eventPropGetter={(myEventsList) => {
            const backgroundColor = myEventsList.colorEvento
              ? myEventsList.colorEvento
              : "red";
            const color = myEventsList.color ? myEventsList.color : "white";
            return { style: { backgroundColor, color } };
          }}
          onSelectEvent={function (event) {
            storeDataAndOpenTheForm(event);
          }}
        />
        </div>
      :
      <div className="col-12">
        <div
        className={`${colClass} d-flex mt-1 justify-content-between ${style.teamMember}`}
        style={{ marginBottom: "-20px" }}
      >
          <div className="text-start fw-bold m-3"> <h3>Client Details ({caseHistory.length || 0})</h3> </div>
          <div className="text-start fw-bold m-3">
            <button className={style.btn} onClick={cancelCaseHistoryEdits}>
              Back
            </button>
          </div>
        </div>
        <div className={`${style.innerTeam} p-2 m-2 col-12 row`}>
                <div className="col-xl-6 col-12 d-flex">
                  <div className="col-4 text-end p-1">
                    <label>Client Name:</label>
                  </div>
                  <div className="col-8 text-start ps-2 pt-1">
                    <label>
                    <a
                      href="reactjs:;"
                    onClick={(e) => {
                      openClientInfoDetailForm(clientData.clientInfo);
                    }}
                    >
                      {clientData?.clientInfo[0]?.clientFullName || ""}
                    </a>
                    </label>
                  </div>
                </div>
                <div className="col-xl-6 col-12 d-flex">
                  <div className="col-4 text-end p-1">
                    <label>Opposite Party Name:</label>
                  </div>
                  <div className="col-8 text-start ps-2 pt-1">
                    <label>
                    <a
                      href="reactjs:;"
                    onClick={(e) => {
                      openClientInfoDetailForm(clientData.oppositeClientInfo);
                    }}
                    >
                      {clientData?.oppositeClientInfo[0]?.oppositePartyFullName || ""}
                    </a>
                    </label>
                  </div>
                </div>
                <div className="col-xl-6 col-12 d-flex">
                <div className="col-4 text-end p-1">
                    <label>Full Case No.:</label>
                  </div>
                  <div className="col-8 text-start ps-2 p-1">
                    <label>{clientData?.fullCaseNo}</label>
                  </div>
                </div>
                <div className="col-xl-6 col-12 d-flex">
                  <div className="col-4 text-end p-1">
                    <label>CNR No.:</label>
                  </div>
                  <div className="col-8 text-start ps-2 p-1">
                    <label>{clientData?.cnrNo}</label>
                  </div>
                </div>
                <div className="col-xl-6 col-12 d-flex">
                  <div className="col-4 text-end p-1">
                    <label>Advocate Name:</label>
                  </div>
                  <div className="col-8 text-start ps-2 p-1">
                    <label>
                      <a 
                      href="reactjs:;"
                      onClick={() =>
                        opendetailform(clientData?.teamMembers)
                      }
                      >
                      {truncateText(clientData.advocateName || "")}
                      {showInfoIcon && (
                        <Tooltip
                          placement="top"
                          title={clientData?.advocateName}
                        >
                          <i>
                            <BsInfoCircle />
                          </i>
                        </Tooltip>
                      )}
                      </a>
                    </label>
                  </div>
                </div>
                <div className="col-xl-6 col-12 d-flex">
                  <div className="col-4 text-end p-1">
                    <label>Court Type:</label>
                  </div>
                  <div className="col-8 text-start ps-2 p-1">
                    <label>{clientData?.courtType}</label>
                  </div>
                </div>
        </div>
        <div className={`d-flex justify-content-center text-center ${style.backgroundColorAndTextColor}`} 
        style={{margin:"-5px 9px -40px 9px", border: "1px solid"}}
        >Case History</div>
      <div className={`${colClass} ${style.overFlowx} m-2`}>
        
        <div
          className={style.innerTeam}
          style={{ display: "table", overflow: "scroll" }}
        >
          <div className={style.teamhead}>
            <h5 className={colClass === "col-10" ? style.id : style.id2}>
              No
            </h5>
              <h5
                className={
                  colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2
                }
              >
                Previous Date
              </h5>
              <h5
                className={
                  `${colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2} 
                    ${style.specialStageForCalender}`
                }
              >
                Special Stage
              </h5>

              <h5
                className={
                  `${colClass === "col-10"
                    ? style.detailsDesignation
                    : style.detailsDesignation2}
                    ${style.remarkForCalender} `
                }
              >
                Remarks
              </h5>
          </div>
          <hr className="m-0" />

          {records
            ? records.map((teamMember, index) => (
                // console.log(teamMember._id)
                <div key={index} className={style.teamDetails}>
                  <p
                    className={
                      colClass === "col-10" ? style.paraId : style.paraId2
                    }
                  >
                    {index + 1}
                  </p>
                  <p
                    className={
                      colClass === "col-10"
                        ? style.detailsDesignation
                        : style.detailsDesignation2
                    }
                  >
                    {moment(teamMember?.hearingDate).format("DD-MMM-YYYY")}
                  </p>
                  <a
                  href="reactjs:;"
                   className={
                    `${colClass === "col-10"
                      ? style.detailsDesignation
                      : style.detailsDesignation2} 
                      ${style.specialStageForCalender}`
                  }
                  onClick={(e) => {
                    openspecialdetailform(teamMember.specialStage, "Special Stage")
                  }}
                  style={{cursor:"pointer"}}
                  >
                    {truncateText(teamMember.specialStage || "")}
                    {showInfoIcon && (
                      <Tooltip
                        placement="top"
                        title={teamMember?.specialStage}
                      >
                        <i>
                          <BsInfoCircle />
                        </i>
                      </Tooltip>
                    )}
                  </a>

                  <a
                  href="reactjs:;"
                    className={
                      `${colClass === "col-10"
                        ? style.detailsDesignation
                        : style.detailsDesignation2}
                        ${style.remarkForCalender} `
                    }
                    onClick={(e) => {
                      openspecialdetailform(teamMember.remark, "Remark")
                    }}
                    style={{cursor:"pointer"}}
                  >
                    {truncateText(teamMember.remark || "")}
                    {showInfoIcon && (
                      <Tooltip
                        placement="top"
                        title={teamMember?.remark}
                      >
                        <i>
                          <BsInfoCircle />
                        </i>
                      </Tooltip>
                    )}
                  </a>
                </div>
              ))
            : ""}
        </div>
        {/* <button className={style.addmore} onClick={openForm}>
      + Add More
    </button> */}
    
    

        <nav className="float-right">
          <ul className="pagination">
            <li className="page-item">
              <a
                href="reactjs:;"
                className="page-link"
                style={{ color: "gray" }}
                onClick={prePage}
              >
                Prev
              </a>
            </li>
            {numbers.map((n, i) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={i}
              >
                <a
                  href="reactjs:;"
                  className="page-link"
                  style={{ color: `${currentPage === n ? "black" : "gray"}` }}
                  onClick={() => changeCPage(n)}
                >
                  {n}
                </a>
              </li>
            ))}
            <li className="page-item">
              <a
                href="reactjs:;"
                className="page-link"
                style={{ color: "gray" }}
                onClick={nextPage}
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
      </div>
      }

{showClientInfoDetail && (
        <div
          openClientInfoDetailForm={showClientInfoDetail}
          className={style.modal}
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <div className={`modal-content ${style.modalContent}`}>
              <div className="modal-header ">
                <h2 className="modal-title">Client Details</h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={closeClientInfoDetailForm}
                  aria-label="Close"
                ></button>
              </div>
              <div className={` ${style.teamForm} mt-5 modal-body py-0`}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    border: "0px solid black",
                    width: "100%",
                  }}
                >
                  <tr style={{ background: "white" }}>
                    <td style={{ background: "gray", color: "white" }}>No.</td>
                    <td style={{ background: "gray", color: "white" }}>
                      {memberGroup[0].clientFullName !== ""
                        ? "Client"
                        : "Advocate"}{" "}
                      Name
                    </td>
                  </tr>
                  {memberGroup.map((n, i) => (
                    <tr
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid black",
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{n.clientFullName || n.oppositePartyFullName}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* {showModal && <Modal onClose={closeModal} showModal={showModal} />} */}
            {showDetailForm && (
              <div
                opendetailform={showDetailForm}
                className={style.modal}
                tabIndex="-1"
              >
                <div className="modal-dialog">
                  <div className={`modal-content ${style.modalContent}`}>
                    <div className="modal-header ">
                      <h2 className="modal-title"> Advocate Details</h2>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={closeDetailForm}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className={` ${style.teamForm} mt-5 modal-body py-0`}>
                      <table
                        style={{
                          borderCollapse: "collapse",
                          border: "0px solid black",
                          width: "100%",
                        }}
                      >
                        <tr style={{ background: "white" }}>
                          <td style={{ background: "gray", color: "white" }}>
                            No.
                          </td>
                          <td style={{ background: "gray", color: "white" }}>
                            Advocate Name
                          </td>
                        </tr>
                        {typeof memberGroup !== "string" ? (
                          memberGroup?.map((n, i) => (
                            <tr
                            key={i}
                              style={{
                                borderCollapse: "collapse",
                                border: "1px solid black",
                              }}
                            >
                              <td>{i + 1}</td>
                              <td>{n.label}</td>
                            </tr>
                          ))
                        ) : (
                          <tr
                          key={1}
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid black",
                            }}
                          >
                            <td>{1}</td>
                            <td>{memberGroup}</td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

      {showSpecialDetailForm && (
              <div
                openspecialdetailform={showSpecialDetailForm}
                className={style.modal}
                tabIndex="-1"
              >
                <div className={`modal-dialog ${style.max900Px}`}>
                  <div className={`modal-content ${style.modalContent}`} style={{width:"900px"}}>
                    <div className="modal-header ">
                      <h2 className="modal-title"> {saveTitle} Details</h2>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={closeSpecialDetailForm}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className={` ${style.teamForm} d-inline mt-5 modal-body py-0`}>
                      <div className={`d-flex col-12 justify-content-center`} style={{border : "1px solid"}}>
                        <p className={`${style.teamDetails} text-center mb-0 col-2 me-1`
                          }>No</p>
                        <p className={`${style.teamDetails} ${style.borderLeft} col-10 text-center mb-0`}>{saveTitle}</p>
                      </div>
                      <div key={1} className={`${style.teamDetails} d-flex pt-0 pb-0 col-12 justify-content-center`} style={{border : "1px solid"}}>
                        <p
                          className={`${style.teamDetails} p-1 me-5 text-center mb-0 col-1`
                          }
                        >
                          {1}
                        </p>
                        <p
                          className={
                            `${style.teamDetails} ${style.borderLeft} text-center mb-0 col-10`
                          }
                          style={{overflow: "auto"}}
                        >
                          {memberGroup}
                        </p>
                      </div>
                      {/* <table
                        style={{
                          borderCollapse: "collapse",
                          border: "0px solid black",
                          width: "100%",
                        }}
                      >
                        <tr style={{ background: "white" }}>
                          <td style={{ background: "gray", color: "white" }}>
                            No.
                          </td>
                          <td style={{ background: "gray", color: "white" }}>
                            {saveTitle}
                          </td>
                        </tr>
                        <tr
                          key={1}
                            style={{
                            borderCollapse: "collapse",
                              border: "1px solid black",
                            }}
                        >
                          <td>{1}</td>
                          <td>{memberGroup}</td>
                        </tr>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

    </div>
    
  );

  

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

};

export default EventCalendars;
