import LoginPage from "./Component/LoginPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashBoard from "./Dashboard/Dashboard";
import Team from "./Team Member/Team";
import MainDashboard from "./Component/MainDashboard";
import MainNotepad from "./Notepad/MainNotepad"
import AddNewClints from "./Client/Clientform";
import Taskmain from "./TaskMain/Taskpage"
import Cases from "./Cases/Allcases"
import ViewClientData from "./Client/Clientviewdata";
import Clienteditdata from "./Client/Clieneditdata";
import PdfClientData from "./Client/PdfClientDowloads";
import AlltodoTask from "./TaskMain/TaskAlltodo";
import Status from "./Team Member/Status";
import Bills from "./Bills Manager/Bills";
import RunningCases from "./Cases/RunningCases";
import ClosedCases from "./Cases/ClosedCases";
import MyDiaryCases from "./My Cases Diary/AllCasesDiary";
import MonthlyActivity from "./Monthly Activity Manager/MonthlyActivityManager";
import BillPdfsData from "./Bills Manager/BillsPdfDownloads";
import EventCalendars from "./EventCalendarManager/EventCalendarManager";
import DocumentManagers from  "./Documents Manager/DocumentsManager";
import AddNewDocumentManagers from "./Documents Manager/AddNewDocumentsManager";
import InvoiceMaking from "./Invoices Manager/InvoiceMakingManager";
import InvoiceMIS from "./Invoices Manager/InvoiceMISManager";
import PdfInvoiceData from "./Invoices Manager/PdfClientDowloads";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exect path='/' element={<LoginPage />} />
          <Route exect path='/Dashboard' element={<DashBoard />} />
          <Route exect path="/MainDashboard" element={<MainDashboard />} />
          <Route exect path='/Team' element={<Team />} />
          <Route exect path='/Notepad' element={<MainNotepad />} />
          <Route exect path='/AddNewClient/:id' element={<AddNewClints />} />
          {/* <Route exect path='/AddNewClient' element={<AddNewClints />} /> */}
          <Route exect path='/Taskmain' element={<Taskmain />} />
          <Route exect path='/AllCases' element={<Cases />} />
          <Route exect path='/ViewClient' element={<ViewClientData />} />
          <Route exect path='/ClientEditMain/:id' element={<Clienteditdata   />} />
          <Route exect path='/PdfClient/:id' element={<PdfClientData />} />
          <Route exect path='/PdfInvoice/:id' element={<PdfInvoiceData />} />
          <Route exect path='/BillPdfs/:id' element={<BillPdfsData />} />
          <Route exect path='/Alltodo' element={<AlltodoTask />} />
          <Route exect path='/Status/:id' element={<Status />} />
          <Route exect path='/Bills' element={<Bills />} />
          <Route exect path='/RunningCases' element={<RunningCases />} />
          <Route exect path='/ClosedCases' element={<ClosedCases />} />
          <Route exect path='/MyDiaryCases' element={<MyDiaryCases />} />
          <Route exect path='/MonthlyActivity' element={<MonthlyActivity />} />
          <Route exect path='/EventCalendar' element={<EventCalendars />} />
          <Route exect path='/DocumentManager' element={<DocumentManagers />} />
          <Route exect path='/AddNewDocumentManager' element={<AddNewDocumentManagers />} />
          <Route exect path='/InvoiceMaking' element={<InvoiceMaking />} />
          <Route exect path='/InvoiceMaking/:id' element={<InvoiceMaking />} />
          <Route exect path='/InvoiceMIS' element={<InvoiceMIS />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App